import {
  Grid,
  styled,
  Slider,
  IconButton,
  Badge,
  BadgeProps,
  Hidden,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Hamburger from "hamburger-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faX } from "@fortawesome/free-solid-svg-icons";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";

const Img = styled("img")(({ theme }) => ({}));
const LinkMenu = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "#000",
}));
const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));
type Props = {};

function Navbar({}: Props) {
  const Navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [bagOpen, setBagOpen] = useState(false);

  function Buttonz() {
    setOpen(!isOpen);
  }
  function openBag() {
    setBagOpen(!bagOpen);
    if (searchOpen === true) {
      setSearchOpen(!searchOpen);
    }
  }
  function openSearch() {
    setSearchOpen(!searchOpen);
    if (bagOpen === true) {
      setBagOpen(!bagOpen);
    }
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
      overflow: "auto",
      border: "1px solid #ccc",
      background: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      padding: "20px",
    },
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const cookies = new Cookies(null, { path: "/" });

  const logout = () => {
    cookies.remove("token");
    sessionStorage.removeItem("role");
    Swal.fire({
      title: "ออกจากระบบ",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  };

  return (
    <>
      <div className="hidden md:flex w-full px-8">
        <div className="flex justify-center w-full">
          <div className="flex items-center w-full lg:justify-between md:justify-center lg:px-8 ">
            <div className="flex w-full gap-[10%]">
              <LinkMenu className="text-[10px] font-bold" to={"/shop"}>
                SHOP
              </LinkMenu>
              <LinkMenu className="text-[10px] font-bold" to={"/Lookbook"}>
                LOOKBOOK
              </LinkMenu>
              <LinkMenu className="text-[10px] font-bold" to={"/Contact"}>
                CONTACT
              </LinkMenu>
            </div>
            <a href="/">
              <div className="flex w-full justify-center">
                <Img
                  src="/LOGO_Talmid.png"
                  className="w-[300px] h-[100px] object-cover"
                />
              </div>
            </a>
            <div className="flex w-full gap-[10%] justify-end">
              <button className="text-[10px] font-bold" onClick={openSearch}>
                SEARCH
              </button>
              <LinkMenu className="text-[10px] font-bold" to={"/wishlist"}>
                WISHLIST
              </LinkMenu>
              {sessionStorage.getItem("role") === null && (
                <LinkMenu className="text-[10px] font-bold" to={"/Login"}>
                  ACCOUNT
                </LinkMenu>
              )}
              <button className="text-[10px] font-bold" onClick={openBag}>
                BAG
              </button>
              {sessionStorage.getItem("role") !== null && (
                <button className="text-[10px] font-bold" onClick={logout}>
                  LOGOUT
                </button>
              )}
            </div>
          </div>
        </div>
        {/* {
                isOpen ? (
                    <div className='fade'>
                        <LinkMenu sx={{ color: "#fff", padding: "6px" }} to={"/"}>Home</LinkMenu>
                        <LinkMenu sx={{ color: "#fff", padding: "6px" }} to={"/shop"}>Shop</LinkMenu>
                        <LinkMenu sx={{ color: "#fff", padding: "6px" }} to={"/collection"}>Collection</LinkMenu>
                        <LinkMenu sx={{ color: "#fff", padding: "6px" }} to={"/contact"}>Contact</LinkMenu>
                    </div>
                ) : (
                    <></>
                )
            } */}
      </div>
      <div className="flex justify-end">
        <button
          onClick={Buttonz}
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded={isOpen ? "true" : "false"}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className=" w-full md:hidden md:w-auto " id="navbar-default">
          <div className=" items-center flex justify-center">
            <a href="/">
              <Img src="/LOGO_Talmid.png" className="w-[300px] h-[120px]" />
            </a>
          </div>
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li className=" text-sm font-semibold">
              <NavLink
                to="/shop"
                onClick={() => {
                  setOpen(false);
                  setSearchOpen(false);
                  setBagOpen(false);
                }}
                className={({ isActive }) =>
                  isActive
                    ? "block py-2 px-3 text-sm font-semibold text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                    : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                }
                aria-current="page"
              >
                SHOP
              </NavLink>
            </li>
            <li className=" text-sm font-semibold">
              <NavLink
                to="/Lookbook"
                onClick={() => {
                  setOpen(false);
                  setSearchOpen(false);
                  setBagOpen(false);
                }}
                className={({ isActive }) =>
                  isActive
                    ? "block py-2 px-3 text-sm font-semibold text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                    : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                }
              >
                LOOKBOOK
              </NavLink>
            </li>
            <li className=" text-sm font-semibold">
              <NavLink
                to="/Contact"
                onClick={() => {
                  setOpen(false);
                  setSearchOpen(false);
                  setBagOpen(false);
                }}
                className={({ isActive }) =>
                  isActive
                    ? "block py-2 px-3 text-sm font-semibold text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                    : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                }
              >
                CONTACT
              </NavLink>
            </li>
            <li className=" text-sm font-semibold">
              <a className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                <button onClick={openSearch}>SEARCH</button>
              </a>
            </li>
            <li className="text-sm font-semibold">
              <NavLink
                to="/wishlist"
                onClick={() => {
                  setOpen(false);
                  setSearchOpen(false);
                  setBagOpen(false);
                }}
                className={({ isActive }) =>
                  isActive
                    ? "block py-2 px-3 text-sm font-semibold text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                    : "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                }
              >
                WISHLIST
              </NavLink>
            </li>
            {sessionStorage.getItem("role") === null && (
              <li className=" text-sm font-semibold">
                <a
                  href="/Login"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  ACCOUNT
                </a>
              </li>
            )}
            <li className=" text-sm font-semibold">
              <a className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                <button onClick={openBag}>BAG</button>
              </a>
            </li>
            <li className=" text-sm font-semibold">
              {sessionStorage.getItem("role") !== null && (
                <button
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                  onClick={logout}
                >
                  LOGOUT
                </button>
              )}
            </li>
          </ul>
        </div>
      )}

      {bagOpen && (
        <>
          <div className="w-full justify-between  flex flex-col h-[400px] border-t-2">
            <div className="flex justify-between border-b-2 p-[1%]">
              <div className="text-sm font-semibold">SHOPPING BAG</div>
              <button onClick={openBag}>
                <FontAwesomeIcon icon={faX} className="px-4" />
              </button>
            </div>
            <div className=" whitespace-pre-line p-[1%] text-xs px-3">
              {
                "YOU HAVE NO ITEMS IN YOUR SHOPPING BAG.\n\nFREE SHIPPING IN US FOR ORDERS OVER $200 USD"
              }
            </div>
            <div className="p-[1%] w-full">
              <button className="bg-black text-white p-2 w-full text-xs">
                CONTINUE SHOPPING
              </button>
            </div>
          </div>
        </>
      )}

      {searchOpen && (
        <>
          <div className="w-full justify-center flex items-center border-t-2">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="px-4" />
            <input type="text" className="w-[80%] border-0 focus:ring-0" />
            <button onClick={openSearch}>
              <FontAwesomeIcon icon={faX} className="px-4" />
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default Navbar;
