import { Box, Divider, Grid, IconButton, Tab, Tabs, Typography, styled } from '@mui/material'
import React from 'react'
import Information from './Information';
import back from '../assets/back.png'
import CloseIcon from '@mui/icons-material/Close';
import Shipping from './Shipping';
import Payment from './Payment';
type Props = {}
const Img = styled("img")(({ theme }) => ({
}));
const Text40px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "32px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "36px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "40px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "44px"
  },
}));
const Text14px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px"
  },
}));
const Text20px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "18px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px"
  },
}));
const Text30px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "26px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "30px"
  },
}));
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Cart({ }: Props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container sx={{ flexDirection: { xs: "column", md: "row" }, width: "90%", padding: "24px 0", justifyContent: "space-between" }}>
      {/* Left */}
      <Grid width={{ xs: "95%", md: "49%" }}>
        <Text30px><b>Checkout</b></Text30px>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ "& .MuiTab-root.Mui-selected": { color: "#000" } }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Information" {...a11yProps(0)} />
              <Tab label="Shipping" {...a11yProps(1)} />
              <Tab label="Payment" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Information  />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Shipping setValue={setValue} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Payment />
          </CustomTabPanel>

        </Box>
      </Grid>

      {/* Right */}
      <Grid width={{ xs: "95%", md: "45%" }} sx={{
        border: "1px solid #D9D9D9", padding: "16px"
      }}>
        <Text14px sx={{ paddingBottom: "12px" }}>YOUR ORDER</Text14px>
        {/* item cart */}
        <Grid container sx={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Img src={back} sx={{ width: { xs: "70px", md: "130px" } }} />
          <Grid sx={{ width: '60%', flexDirection: "column", justifyContent: "space-between" }} container>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Text20px>Lorem ipsum dolor </Text20px>
              <IconButton>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Text14px>Black/L</Text14px>
            <Grid container sx={{ justifyContent: "space-between" }}>
              <Text20px>(1)</Text20px>
              <Text20px>
                <b>
                  590฿
                </b>
              </Text20px>
            </Grid>

          </Grid>
        </Grid>
        <Divider />
        <Grid container sx={{ justifyContent: "space-between", alignItems: "center", padding: "2px 16px" }}>
          <Text14px>Subtotal</Text14px>
          <Text20px>
            <b>
              590฿
            </b>
          </Text20px>
        </Grid>
        <Grid container sx={{ justifyContent: "space-between", alignItems: "center", padding: "2px 16px" }}>
          <Text14px>Shipping</Text14px>
          <Text14px>
            Calculated at next step
          </Text14px>
        </Grid>
        <Divider />
        <Grid container sx={{ justifyContent: "space-between", alignItems: "center", padding: "2px 16px" }}>
          <Text14px>Total</Text14px>
          <Text20px>
            <b>
              590฿
            </b>
          </Text20px>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Cart