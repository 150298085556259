import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import {
  Grid,
  Button,
  FormControl,
  FormLabel,
  TextField,
  Box,
} from "@mui/material";
import Cookies from "universal-cookie";
import { DataGrid, GridColDef, GridRowModel } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Category = () => {
  const cookies = new Cookies(null, { path: "/" });
  const navigate = useNavigate();

  const [categoriesRef, setCategoriesRef] = useState<any>([]);
  const nameRef = useRef<any>(null);

  const columns: GridColDef[] = [
    { field: "name", headerName: "ชื่อหมวดหมู่", flex: 1 },
    { field: "count", headerName: "รายการสินค้าในหมวดหมู่", flex: 1 },
    {
      field: "action",
      headerName: "คำสั่ง",
      flex: 1,
      renderCell: (r) => {
        return (
          <Button
            variant={"contained"}
            color="error"
            size={"small"}
            onClick={() => {
              deleteCategories(r.row.id);
            }}
          >
            <DeleteOutline fontSize="small"></DeleteOutline>
          </Button>
        );
      },
    },
  ];

  const getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/categories/all`)
      .then((response) => {
        const data = response.data;

        setCategoriesRef(data);
      });
  };

  const addCategories = async () => {
    const name = nameRef.current.value;

    if (name != null && name !== "" && name !== undefined) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/categories/add`,
          {
            name: name,
          },
          {
            headers: {
              token: cookies.get("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.message === "OK") {
            Swal.fire({
              title: "เพิ่มหมวดหมู่สินค้าเรียบร้อยแล้ว",
              icon: "success",
            });
            const timer = setTimeout(() => {
              window.location.reload();
            }, 3000);

            return () => clearTimeout(timer);
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: `เพิ่มหมวดหมู่สินค้าไม่สำเร็จ (${response.data.message})`,
              icon: "error",
            });
          }
        });
    } else {
      const notify = (msg: any, options: any) => toast(msg, { ...options });
      notify("Please input categories name", {
        duration: 5000,
        style: {
          color: "white",
          backgroundColor: "red",
        },
        icon: "❌",
        position: "bottom-center",
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      });
    }
  };

  const deleteCategories = async (id: any) => {
    if (id != null && id !== "" && id !== undefined) {
      Swal.fire({
        title: "ยืนยันการหมวดหมู่สินค้านี้ ?",
        text: "แน่ใจที่จะลบหมวดหมู่สินค้านี้ใช่หรือไม่ หากใช่หมวดหมู่สินค้านี้จะถูกลบออกไป",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor:"#32a852",
        cancelButtonColor:"#a83232",
        confirmButtonText: "ใช่, ลบเลย!",
        cancelButtonText: "ไม่, ยกเลิก!",
      }).then((status) => {
        if (status.isConfirmed) {
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/categories/delete`,
              {
                id: id,
              },
              {
                headers: {
                  token: cookies.get("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.message === "OK") {
                Swal.fire({
                  title: "ลบหมวดหมู่สินค้าเรียบร้อยแล้ว",
                  icon: "success",
                });
                const timer = setTimeout(() => {
                  window.location.reload();
                }, 3000);

                return () => clearTimeout(timer);
              } else {
                Swal.fire({
                  title: "เกิดข้อผิดพลาด",
                  text: `ลบหมวดหมู่สินค้าไม่สำเร็จ (${response.data.message})`,
                  icon: "error",
                });
              }
            });
        }
      });
    } else {
      const notify = (msg: any, options: any) => toast(msg, { ...options });
      notify("Choose your categories for delete", {
        duration: 5000,
        style: {
          color: "white",
          backgroundColor: "red",
        },
        icon: "❌",
        position: "bottom-center",
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      });
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Toaster />
          <FormControl fullWidth>
            <FormLabel>ชื่อหมวดหมู่</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="ชื่อหมวดหมู่"
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
              inputRef={nameRef}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormLabel>&nbsp;</FormLabel>
          <Button
            onClick={() => {
              addCategories();
            }}
            fullWidth
            variant={"contained"}
            size={"large"}
          >
            + เพิ่มหมวดหมู่
          </Button>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            sx={{ width: { xs: 200, sm: 200, md: "100%" } }}
            style={{ overflow: "auto" }}
          >
            <DataGrid
              sx={{ width: { xs: 400, sm: 400, md: "100%" } }}
              // getRowId={(row: GridRowModel) => row.id}
              // getRowHeight={() => 'auto'}
              rows={categoriesRef}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 20, 50, 100]}
              rowSelection={false}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Category;
