import React, { useState, useEffect } from "react";
import { Modal, Typography, Box, styled, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "aos/dist/aos.css";
import AOS from "aos";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";

interface Image {
  id: number;
  image: string;
  name: string;
  price: string;
  description: string;
  gender: string;
}

function Lookbook() {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const [filterGender, setFilterGender] = useState<string | null>(null);

  const handleOpen = (image: Image) => {
    setSelectedImage(image);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleFilterChange = (gender: string | null) => {
    setFilterGender(gender);
  };

  const Text20px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px",
    },
  }));

  const Text30px = styled(Typography)(({ theme }) => ({
    fontWeight: "600",
    [theme.breakpoints.up("xs")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "12px",
    },
  }));

  const images: Image[] = [
    {
      id: 1,
      image: "/Talmid-1.jpg",
      name: "01 AFFECTION TEE",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "MEN",
    },
    {
      id: 2,
      image: "/Talmid-2.jpg",
      name: "01 AFFECTION TEE",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "MEN",
    },
    {
      id: 3,
      image: "/Talmid-3.jpg",
      name: "01 AFFECTION TEE",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 4,
      image: "/Talmid-4.jpg",
      name: "01 AFFECTION TEE",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 5,
      image: "/Talmid-5.jpg",
      name: "01 AFFECTION TEE",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 6,
      image: "/Talmid-6.jpg",
      name: "01 AFFECTION TEE",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "MEN&WOMEN",
    },
    {
      id: 7,
      image: "/Talmid-7.jpg",
      name: "02 AFFECTION LS TEE",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "MEN",
    },
    {
      id: 8,
      image: "/Talmid-8.jpg",
      name: "02 AFFECTION LS TEE",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "MEN",
    },
    {
      id: 9,
      image: "/Talmid-9.jpg",
      name: "02 AFFECTION LS TEE",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 10,
      image: "/Talmid-10.jpg",
      name: "02 AFFECTION LS TEE",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 11,
      image: "/Talmid-11.jpg",
      name: "03 AFFECTION WASHED TEE",
      price: "1,290 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "MEN",
    },
    {
      id: 12,
      image: "/Talmid-12.jpg",
      name: "03 AFFECTION WASHED TEE",
      price: "1,290 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "MEN",
    },
    {
      id: 13,
      image: "/Talmid-13.jpg",
      name: "03 AFFECTION WASHED TEE",
      price: "1,290 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 14,
      image: "/Talmid-14.jpg",
      name: "03 AFFECTION WASHED TEE",
      price: "1,290 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "MEN&WOMEN",
    },
    {
      id: 15,
      image: "/Talmid-15.jpg",
      name: "03 AFFECTION WASHED TEE",
      price: "1,290 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "MEN&WOMEN",
    },
    {
      id: 16,
      image: "/Talmid-16.jpg",
      name: "01 AFFECTION TEE",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 17,
      image: "/Talmid-17.jpg",
      name: "02 AFFECTION LS TEE",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "MEN",
    },
    {
      id: 18,
      image: "/Talmid-18.jpg",
      name: "02 AFFECTION LS TEE",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "MEN",
    },
    {
      id: 19,
      image: "/Talmid-19.jpg",
      name: "02 AFFECTION LS TEE",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 20,
      image: "/Talmid-20.jpg",
      name: "02 AFFECTION LS TEE",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 21,
      image: "/Talmid-21.jpg",
      name: "04 MARRY ME TEE (WHITE)",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "MEN&WOMEN",
    },
    {
      id: 22,
      image: "/Talmid-22.jpg",
      name: "04 MARRY ME TEE (WHITE)",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 23,
      image: "/Talmid-23.jpg",
      name: "05 MARRY ME LS TEE (WHITE)",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "MEN",
    },
    {
      id: 24,
      image: "/Talmid-24.jpg",
      name: "05 MARRY ME LS TEE (WHITE)",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 25,
      image: "/Talmid-25.jpg",
      name: "04 MARRY ME TEE (BLACK)",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "MEN&WOMEN",
    },
    {
      id: 26,
      image: "/Talmid-26.jpg",
      name: "04 MARRY ME TEE (BLACK)",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "MEN&WOMEN",
    },
    {
      id: 27,
      image: "/Talmid-27.jpg",
      name: "05 MARRY ME LS TEE (BLACK)",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "MEN&WOMEN",
    },
    {
      id: 28,
      image: "/Talmid-28.jpg",
      name: "05 MARRY ME LS TEE (BLACK)",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 29,
      image: "/Talmid-29.jpg",
      name: "05 MARRY ME LS TEE (BLACK)",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 30,
      image: "/Talmid-30.jpg",
      name: "06 TETELESTAI TEE (BLACK)",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "MEN&WOMEN",
    },
    {
      id: 31,
      image: "/Talmid-31.jpg",
      name: "06 TETELESTAI TEE (BLACK)",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "MEN&WOMEN",
    },
    {
      id: 32,
      image: "/Talmid-32.jpg",
      name: "06 TETELESTAI TEE (BLACK)",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 33,
      image: "/Talmid-33.jpg",
      name: "06 TETELESTAI TEE (BLACK)",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 34,
      image: "/Talmid-34.jpg",
      name: "07 TETELESTAI LS TEE (BLACK)",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 35,
      image: "/Talmid-35.jpg",
      name: "07 TETELESTAI LS TEE (BLACK)",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 36,
      image: "/Talmid-36.jpg",
      name: "07 TETELESTAI LS TEE (BLACK)",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 37,
      image: "/Talmid-37.jpg",
      name: "08 SACRIFICED TEE (BLACK)",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "MEN",
    },
    {
      id: 38,
      image: "/Talmid-38.jpg",
      name: "08 SACRIFICED TEE (BLACK)",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "MEN",
    },
    {
      id: 39,
      image: "/Talmid-39.jpg",
      name: "08 SACRIFICED TEE (BLACK)",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 40,
      image: "/Talmid-40.jpg",
      name: "08 SACRIFICED TEE (BLACK)",
      price: "790 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Shortsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 41,
      image: "/Talmid-41.jpg",
      name: "09 SACRIFICED LS TEE (BLACK)",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "WOMEN",
    },
    {
      id: 42,
      image: "/Talmid-42.jpg",
      name: "09 SACRIFICED LS TEE (BLACK)",
      price: "1,090 ฿",
      description:
        "Details\n - Oversized Tee\n- Material: 100% Cotton\n- Longsleeve\n- Unisex",
      gender: "WOMEN",
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);

  const filteredImages = filterGender
    ? images.filter((img) => img.gender === filterGender)
    : images;

  return (
    <div className="container mx-auto px-10 lg:px-52  py-8">
      <div
        className="flex justify-center text-lg font-bold md:text-xl mb-5"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        LOOKBOOK
      </div>

      {/* <div className="flex justify-center mb-4">
        <button onClick={() => handleFilterChange(null)} className="px-4 py-2 bg-white border-black border rounded-lg hover:text-white hover:bg-black transform duration-300 mx-2">All</button>
        <button onClick={() => handleFilterChange('MEN')} className="px-4 py-2 bg-white border-black border rounded-lg hover:text-white hover:bg-black transform duration-300 mx-2">Men</button>
        <button onClick={() => handleFilterChange('WOMEN')} className="px-4 py-2 bg-white border-black border rounded-lg hover:text-white hover:bg-black transform duration-300 mx-2">Women</button>
        <button onClick={() => handleFilterChange('MEN&WOMEN')} className="px-4 py-2 bg-white border-black border rounded-lg hover:text-white hover:bg-black transform duration-300 mx-2">Men & Women</button>
      </div> */}

      <div
        className="flex justify-center mb-10"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <span
          onClick={() => handleFilterChange(null)}
          className={`cursor-pointer px-3 py-2  transform duration-300 text-sm md:text-base ${
            filterGender === null
              ? "font-bold text-black"
              : "text-gray-500 hover:text-black"
          }`}
        >
          All
        </span>
        <span
          onClick={() => handleFilterChange("MEN")}
          className={`cursor-pointer px-3 py-2  transform duration-300 text-sm md:text-base ${
            filterGender === "MEN"
              ? "font-bold text-black"
              : "text-gray-500 hover:text-black"
          }`}
        >
          Men
        </span>
        <span
          onClick={() => handleFilterChange("WOMEN")}
          className={`cursor-pointer px-3 py-2  transform duration-300 text-sm md:text-base ${
            filterGender === "WOMEN"
              ? "font-bold text-black"
              : "text-gray-500 hover:text-black"
          }`}
        >
          Women
        </span>
        {/* <span
          onClick={() => handleFilterChange("MEN&WOMEN")}
          className={`cursor-pointer px-3 py-2  transform duration-300 text-sm md:text-base ${
            filterGender === "MEN&WOMEN"
              ? "font-bold text-black"
              : "text-gray-500 hover:text-black"
          }`}
        >
          Men & Women
        </span> */}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {filteredImages.map((img) => (
          <div
            key={img.id}
            className="w-full h-full cursor-pointer"
            onClick={() => handleOpen(img)}
          >
            <img
              src={img.image}
              alt={`Talmid ${img.id}`}
              className="w-full h-full object-cover"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-anchor-placement="top-bottom"
            />
          </div>
        ))}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: "flex",
          border: "none",
          alignItems: "center",
          justifyContent: "center",
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "100vh",
            overflowY: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            border: "none",
            outline: "none",
            width: { xs: "80%", md: "50%" },
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", top: 4, right: 4 }}
          >
            <CloseIcon />
          </IconButton>

          {selectedImage && (
            <>
              <img
                src={selectedImage.image}
                alt={selectedImage.name}
                className=" md:w-[70%] lg:w-[65%] md:mt-32 mb-10 object-cover"
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* <img
                  src="/LOGO_Talmid.png"
                  className="w-[35%] md:w-[30%] object-cover md:mr-2"
                  alt=""
                /> */}
                {/* <Text30px id="modal-title" sx={{ textAlign: "left" }}>
                  <a href={`/detail/${135}`}>{selectedImage.name}</a>
                </Text30px> */}
                <Text30px id="modal-title" sx={{ textAlign: "left" }}>
                  {selectedImage.name}
                </Text30px>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default Lookbook;
