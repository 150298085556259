import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import React,{ useEffect } from "react";
import T from "../assets/T.png";
import { Link, useNavigate } from "react-router-dom";
import ProductComponent from "../components/ProductComponent";
import ShopComponent from "../components/ShopComponent";
import 'aos/dist/aos.css';
import AOS from 'aos';

type Props = {};
const Img = styled("img")(({ theme }) => ({}));
const Text40px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "32px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "36px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "40px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "44px",
  },
}));
const Text14px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
  },
}));
const Text20px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px",
  },
}));
const Text30px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "26px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "30px",
  },
}));
const BTN = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12pxpx",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
  },
}));
function Shop({}: Props) {
  const Navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const [open1, setOpen1] = React.useState(false);
  const handleClick1 = () => {
    setOpen1(!open1);
  };
  const [open2, setOpen2] = React.useState(false);
  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const [open3, setOpen3] = React.useState(false);
  const handleClick3 = () => {
    setOpen3(!open3);
  };
  const [open4, setOpen4] = React.useState(false);
  const handleClick4 = () => {
    setOpen4(!open4);
  };
  const [open5, setOpen5] = React.useState(false);
  const handleClick5 = () => {
    setOpen5(!open5);
  };

  var settings = {
    className: "center",
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  const images = [
    "/image20.png",
    "/image20.png",
    "/image20.png",
    "/image20.png",
    "/image20.png",
    "/image20.png",
    "/image20.png",
    "/image20.png",
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="flex justify-center flex-col" data-aos="fade-up" data-aos-duration="1000">
      <div className="my-16">
        <ShopComponent />

        {/* <div className="flex justify-center w-full h-full mt-20 ">
          <Button
            className="w-[371px] h-[51px] text-[20px]"
            variant="contained"
            sx={{
              margin: "0 auto",
              color: "white",
              backgroundColor: "#323335",
            }}
          >
            load more products
          </Button>
        </div> */}
      </div>
    </div>
  );
}

export default Shop;
