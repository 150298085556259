import {
  AccountCircle,
  CloudUploadOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Grid,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import AddProduct from "./AddProduct";
import Category from "./Category";
import Banner from "./Banner";
import ConfigWebsite from "./ConfigWebsite";
import Cookies from "universal-cookie";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import EditProduct from "./EditProduct";
import EditUser from "./EditUser";
import EditContact from "./EditContact";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Swal from "sweetalert2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface BarChartProps {
  data: {
    labels: string[];
    values: number[];
  };
}

const listOfMenus = [
  {
    name: "Dashboard",
    path: "Dashboard",
  },
  {
    name: "Order",
    path: "Order",
  },
  {
    name: "สินค้าทั้งหมด",
    path: "Product",
  },
  {
    name: "เพิ่มสินค้าใหม่",
    path: "AddProduct",
  },
  {
    name: "หมวดหมู่ทั้งหมด",
    path: "Category",
  },
  // {
  //   name: "ตั้งค่า Banner",
  //   path: "Banner",
  // },
  // {
  //   name: "จัดการ User",
  //   path: "UserManage",
  // },
  // {
  //   name: "จัดการ Contact Us",
  //   path: "ContactManage",
  // },
  {
    name: "Logout",
    path: "Logout",
  },
];

const AdminPage = () => {
  const [tab, setTab] = React.useState<string>("Dashboard");
  const cookies = new Cookies(null, { path: "/" });
  const navigate = useNavigate();
  const [product_id, setProduct_id] = React.useState<string>("");
  const [user_id, setUser_id] = React.useState<string>("");
  const [contact_id, setContact_id] = React.useState<string>("");
  const [OrderID, setOrderID] = React.useState<string>("");

  const [productRef, setProductRef] = useState<any>([]);
  const [user, setUser] = useState<any>([]);
  const [contact, setContact] = useState<any>([]);
  const [order, setOrder] = useState<any>([]);

  useEffect(() => {
    getProducts();
    // getAllProfile();
    // getAllContact();
    getAllOrder();
  }, []);

  const getProducts = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/products/all`)
      .then((response) => {
        const data = response.data;
        console.log(data);
        setProductRef(data);
      });
  };

  const OrderPaymentTrue = () => {
    console.log(OrderID);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/orders/updateOrderPayment/`,
        {
          id: OrderID,
          status: "1",
        },
        {
          headers: {
            token: cookies.get("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.message === "OK") {
          getAllOrder();
        }
      });
  };
  const OrderPaymentFalse = () => {
    console.log(OrderID);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/orders/updateOrderPayment/`,
        {
          id: OrderID,
          status: "0",
        },
        {
          headers: {
            token: cookies.get("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.message === "OK") {
          getAllOrder();
        }
      });
  };
  const OrderShippingTrue = () => {
    console.log(OrderID);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/orders/updateOrderShipping/`,
        {
          id: OrderID,
          status: "1",
        },
        {
          headers: {
            token: cookies.get("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.message === "OK") {
          getAllOrder();
        }
      });
  };
  const OrderShippingFalse = () => {
    console.log(OrderID);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/orders/updateOrderShipping/`,
        {
          id: OrderID,
          status: "0",
        },
        {
          headers: {
            token: cookies.get("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.message === "OK") {
          getAllOrder();
        }
      });
  };

  // const getAllProfile = () => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/members/getAllProfile/`, {
  //       headers: {
  //         token: cookies.get("token"),
  //       },
  //     })
  //     .then((response) => {
  //       const data = response.data;
  //       console.log(data);
  //       setUser(data);
  //     });
  // };

  // const getAllContact = () => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/members/getAllContact/`, {
  //       headers: {
  //         token: cookies.get("token"),
  //       },
  //     })
  //     .then((response) => {
  //       const data = response.data;
  //       console.log(data);
  //       setContact(data);
  //     });
  // };

  const getAllOrder = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/orders/getAllOrder/`, {
        headers: {
          token: cookies.get("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        console.log(data);
        setOrder(data);
      });
  };

  // Chart
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    values: [65, 59, 80, 81, 56, 55, 40, 60, 70, 75, 90, 100],
  };

  const BarChart: React.FC<BarChartProps> = ({ data }) => {
    const chartData = {
      labels: data.labels,
      datasets: [
        {
          label: "ยอดขาย",
          data: data.values,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
        },
        {
          label: "ราคาสินค้าทั้งหมด",
          data: data.values,
          backgroundColor: "rgba(192,75,192,0.2)",
          borderColor: "rgba(192,75,192,1)",
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top" as const,
        },
        title: {
          display: true,
          text: "ยอดขายทั้งหมด",
        },
      },
    };

    return <Bar data={chartData} options={options} />;
  };

  if (tab === "Logout") {
    Swal.fire({
      title: "ออกจากระบบ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        cookies.remove("token");
        sessionStorage.removeItem("role");
        Swal.fire({
          title: "ออกจากระบบ",
          icon: "success",
        }).then(() => {
          return navigate("/");
        });
      } else {
        setTab("Dashboard");
      }
    });
  }

  return (
    <Box p={3}>
      <Paper elevation={2}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={4} p={2}>
            <Paper elevation={2}>
              <List
                sx={{ width: "100%", bgcolor: "background.paper" }}
                subheader={
                  <ListSubheader
                    style={{ background: "#069a02", color: "white" }}
                    component="div"
                    id="nested-list-subheader"
                  >
                    เมนู
                  </ListSubheader>
                }
              >
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={tab}
                  onChange={(event: React.SyntheticEvent, newValue: string) => {
                    setTab(newValue);
                    console.log(newValue);
                  }}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}
                >
                  {listOfMenus.map((item, index) => {
                    return (
                      <Tab
                        key={index}
                        value={item.path}
                        label={item.name}
                        sx={{
                          alignItems: "flex-start",
                          justifyContent: "flex-start", // Align label to the start
                          textAlign: "left", // Align text to the left
                          minWidth: "auto", // Allow tab to adjust width based on content
                        }}
                      />
                    );
                  })}
                </Tabs>
              </List>
            </Paper>
          </Grid>
          <Grid item md={8} lg={8} p={2}>
            <TabContext value={tab}>
              <TabPanel value={"Product"}>
                <TextField
                  fullWidth
                  id="standard-bare"
                  variant="outlined"
                  placeholder="ค้นหาสินค้า"
                  InputProps={{
                    sx: {
                      borderRadius: "25px",
                      height: "45px",
                    },
                    startAdornment: (
                      <IconButton>
                        <SearchOutlined />
                      </IconButton>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      py: "8px",
                      fontSize: "14px",
                    },
                  }}
                />

                <Grid container p={2} spacing={2}>
                  {productRef.map((item: any, index: any) => {
                    return (
                      <Grid item key={index} xs={12} sm={6} md={3}>
                        <Card>
                          <CardActionArea
                            onClick={() => {
                              setProduct_id(item.id);
                              setTab("EditProduct");
                            }}
                          >
                            <CardMedia
                              component="img"
                              style={{
                                minWidth: "100%",
                                objectFit: "contain",
                              }}
                              image={
                                item.path
                                  ? `${item.path.replace(
                                      `{api_path}`,
                                      process.env.REACT_APP_API_URL
                                    )}`
                                  : "/404-not-found.png"
                              }
                              alt="green iguana"
                            />
                            <CardContent>
                              <Typography
                                variant={"body2"}
                                color="text.secondary"
                                textAlign={"center"}
                              >
                                {item.id}
                              </Typography>
                              <Typography
                                variant={"body1"}
                                color="text.secondary"
                                textAlign={"center"}
                              >
                                {item.name}
                              </Typography>
                              <Typography
                                variant={"body2"}
                                color="text.secondary"
                                textAlign={"center"}
                              >
                                {item.price}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>

              <TabPanel value={"UserManage"}>
                <TextField
                  fullWidth
                  id="standard-bare"
                  variant="outlined"
                  placeholder="ค้นหาสินค้า"
                  InputProps={{
                    sx: {
                      borderRadius: "25px",
                      height: "45px",
                    },
                    startAdornment: (
                      <IconButton>
                        <SearchOutlined />
                      </IconButton>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      py: "8px",
                      fontSize: "14px",
                    },
                  }}
                />

                <Grid container p={2} spacing={2}>
                  {/* {user.map((item: any, index: any) => {
                    return (
                      <Grid item key={index} xs={12} sm={6} md={6}>
                        <Card>
                          <CardActionArea
                            onClick={() => {
                              setUser_id(index);
                              setTab("EditUser");
                            }}
                          >
                            <CardContent>
                              <Typography
                                variant={"body1"}
                                color="text.secondary"
                                textAlign={"center"}
                              >
                                {item.isAdmin === 1 ? "Admin" : "User"}
                              </Typography>
                              <Typography
                                variant={"body2"}
                                color="text.secondary"
                                textAlign={"center"}
                              >
                                {item.email}
                              </Typography>
                              <Typography
                                variant={"body2"}
                                color="text.secondary"
                                textAlign={"center"}
                              >
                                {item.phone}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })} */}
                </Grid>
              </TabPanel>

              <TabPanel value={"ContactManage"}>
                <Grid container p={2} spacing={2}>
                  {/* {contact.map((item: any, index: any) => {
                    return (
                      <Grid item key={index} xs={12} sm={6} md={6}>
                        <Card>
                          <CardActionArea
                            onClick={() => {
                              setContact_id(index);
                              setTab("EditContact");
                            }}
                          >
                            <CardMedia
                              component="img"
                              style={{
                                minWidth: "100%",
                                objectFit: "contain",
                              }}
                              image={
                                item.img_path
                                  ? `${item.img_path.replace(
                                      `{api_path}`,
                                      process.env.REACT_APP_API_URL
                                    )}`
                                  : "/404-not-found.png"
                              }
                              alt="green iguana"
                            />
                            <CardContent>
                              <Typography
                                variant={"body1"}
                                color="text.secondary"
                                textAlign={"center"}
                              >
                                {item.name}
                              </Typography>
                              <Typography
                                variant={"body2"}
                                color="text.secondary"
                                textAlign={"center"}
                              >
                                {item.role}
                              </Typography>
                              <Typography
                                variant={"body2"}
                                color="text.secondary"
                                textAlign={"center"}
                              >
                                {item.line}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })} */}
                </Grid>
              </TabPanel>

              <TabPanel value={"Dashboard"}>
                <Grid container p={2} spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography fontSize={"36px"}>ภาพรวม</Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <BarChart data={data} />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <Card>
                      <CardContent>
                        <Typography variant={"body1"} color="text.secondary">
                          ยอดขายรวม
                        </Typography>
                        <Typography variant={"body2"} color="text.secondary">
                          ฿ 101
                        </Typography>
                        <Typography
                          variant={"body2"}
                          color="text.secondary"
                          textAlign={"center"}
                        >
                          เพิ่มขึ้น 100 %
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <Card>
                      <CardContent>
                        <Typography variant={"body1"} color="text.secondary">
                          ยอดขายเดือนนี้
                        </Typography>
                        <Typography variant={"body2"} color="text.secondary">
                          ฿ 101
                        </Typography>
                        <Typography
                          variant={"body2"}
                          color="text.secondary"
                          textAlign={"center"}
                        >
                          เพิ่มขึ้น 100 %
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <Card>
                      <CardContent>
                        <Typography variant={"body1"} color="text.secondary">
                          ราคาสินค้ารวม
                        </Typography>
                        <Typography variant={"body2"} color="text.secondary">
                          ฿ 101
                        </Typography>
                        <Typography
                          variant={"body2"}
                          color="text.secondary"
                          textAlign={"center"}
                        >
                          จากสินค้า 10 ชิ้น
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <Card>
                      <CardContent>
                        <Typography variant={"body1"} color="text.secondary">
                          สินค้าทั้งหมด
                        </Typography>
                        <Typography variant={"body2"} color="text.secondary">
                          1000 ชิ้น
                        </Typography>
                        <Typography
                          variant={"body2"}
                          color="text.secondary"
                          textAlign={"center"}
                        >
                          เพิ่ม 10 % จากเดือนก่อน
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography fontSize={"24px"}>
                      สินค้าขายดี 5 อันดับ
                    </Typography>
                  </Grid>
                  {[1, 2, 3, 4, 5].map((item: any, index: any) => {
                    return (
                      <Grid item key={item} xs={12} sm={12} md={12} lg={12}>
                        <Card>
                          <CardContent>
                            <div className="flex justify-between">
                              <div>ลำดับ {index + 1}</div>
                              <div>ชื่อสินค้า</div>
                              <div>ราคา</div>
                              <div>จำนวน</div>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </TabPanel>

              {/* <TabPanel value={"Order"}>
                {order &&
                  order.map((item: any, index: any) => {
                    return (
                      <Grid item key={item} xs={12} sm={12} md={12} lg={12}>
                        <Card>
                          <CardContent>
                            <div className="flex justify-between w-full h-48 ">
                              <div className="w-1/4 p-4 overflow-y-auto">
                              <h2 className="font-semibold text-lg mb-2">คำสั่งซื้อ</h2>
                                สินค้า <br /> {item.data}
                                <br />
                                ทั้งหมด {item.piece} ชิ้น
                                <br />
                                ราคารวม {item.price} บาท
                                <br />
                                <br />
                                {item.status_payment === "1" && (
                                  <button
                                    type="button"
                                    value={item.id}
                                    className="w-full rounded-2xl bg-green-300"
                                    onClick={(e) => {
                                      setOrderID(e.currentTarget.value);
                                      OrderPaymentFalse();
                                    }}
                                  >
                                    สถานะจ่ายเงิน (สำเร็จ)
                                  </button>
                                )}
                                {item.status_payment === "0" && (
                                  <button
                                    type="button"
                                    value={item.id}
                                    className="w-full rounded-2xl bg-red-300"
                                    onClick={(e) => {
                                      setOrderID(e.currentTarget.value);
                                      OrderPaymentTrue();
                                    }}
                                  >
                                    สถานะจ่ายเงิน (ไม่สำเร็จ)
                                  </button>
                                )}
                                <br />
                                <br />
                                {item.status_shipping === "1" && (
                                  <button
                                    type="button"
                                    value={item.id}
                                    className="w-full rounded-2xl bg-green-300"
                                    onClick={(e) => {
                                      setOrderID(e.currentTarget.value);
                                      OrderShippingFalse();
                                    }}
                                  >
                                    สถานะขนส่ง (สำเร็จ)
                                  </button>
                                )}
                                {item.status_shipping === "0" && (
                                  <button
                                    type="button"
                                    value={item.id}
                                    className="w-full rounded-2xl bg-red-300"
                                    onClick={(e) => {
                                      setOrderID(e.currentTarget.value);
                                      OrderShippingTrue();
                                    }}
                                  >
                                    สถานะขนส่ง (ไม่สำเร็จ)
                                  </button>
                                )}
                              </div>
                              <div className="w-1/4 p-4 overflow-y-auto">
                              <h2 className="font-semibold text-lg mb-2">ข้อมูลจัดส่ง</h2>
                                {item.address}
                              </div>
                              <div className="w-2/4 p-4 overflow-y-auto">
                              <h2 className="font-semibold text-lg mb-2">รูปสลิป</h2>
                                <img
                                  src={item.path_img.replace(
                                    "{api_path}",
                                    process.env.REACT_APP_API_URL
                                  )}
                                  alt=""
                                  className="w-full object-contain"
                                />
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
              </TabPanel> */}

              <TabPanel value={"Order"}>
                <Grid container spacing={2}>
                  {/* ส่วนหัวข้อต่างๆ */}
                  <Grid item xs={12}>
                  <div className="flex justify-between">
                    <h2 className="font-semibold text-lg md:text-xl mb-2 w-1/4 text-center">คำสั่งซื้อ</h2>
                    <h2 className="font-semibold text-lg md:text-xl mb-2 w-1/4 text-center">ข้อมูลจัดส่ง</h2>
                    <h2 className="font-semibold text-lg md:text-xl mb-2 w-2/4 text-center">รูปสลิป</h2>
                  </div>
                  </Grid>
                  
                  {/* ส่วนการ์ดที่ถูก map */}
                  {order && order.map((item: any, index: any) => (
                    <Grid item key={index} xs={12}>
                      <Card>
                        <CardContent>
                          <div className="flex justify-between w-full h-48">
                            
                            {/* คำสั่งซื้อ */}
                            <div className="w-1/4 p-4 overflow-y-auto">
                              สินค้า <br /> {item.data}
                              <br />
                              ทั้งหมด {item.piece} ชิ้น
                              <br />
                              ราคารวม {item.price} บาท
                              <br />
                              <br />
                              {item.status_payment === "1" && (
                                <button
                                  type="button"
                                  value={item.id}
                                  className="w-full rounded-2xl bg-green-300"
                                  onClick={(e) => {
                                    setOrderID(e.currentTarget.value);
                                    OrderPaymentFalse();
                                  }}
                                >
                                  สถานะจ่ายเงิน (สำเร็จ)
                                </button>
                              )}
                              {item.status_payment === "0" && (
                                <button
                                  type="button"
                                  value={item.id}
                                  className="w-full rounded-2xl bg-red-300"
                                  onClick={(e) => {
                                    setOrderID(e.currentTarget.value);
                                    OrderPaymentTrue();
                                  }}
                                >
                                  สถานะจ่ายเงิน (ไม่สำเร็จ)
                                </button>
                              )}
                              <br />
                              <br />
                              {item.status_shipping === "1" && (
                                <button
                                  type="button"
                                  value={item.id}
                                  className="w-full rounded-2xl bg-green-300"
                                  onClick={(e) => {
                                    setOrderID(e.currentTarget.value);
                                    OrderShippingFalse();
                                  }}
                                >
                                  สถานะขนส่ง (สำเร็จ)
                                </button>
                              )}
                              {item.status_shipping === "0" && (
                                <button
                                  type="button"
                                  value={item.id}
                                  className="w-full rounded-2xl bg-red-300"
                                  onClick={(e) => {
                                    setOrderID(e.currentTarget.value);
                                    OrderShippingTrue();
                                  }}
                                >
                                  สถานะขนส่ง (ไม่สำเร็จ)
                                </button>
                              )}
                            </div>
                            
                            {/* ข้อมูลจัดส่ง */}
                            <div className="w-1/4 p-4 overflow-y-auto">
                              {item.address}
                            </div>
                            
                            {/* รูปสลิป */}
                            <div className="w-2/4 p-4 overflow-y-auto">
                              <img
                                src={item.path_img.replace(
                                  "{api_path}",
                                  process.env.REACT_APP_API_URL
                                )}
                                alt="สลิปการจ่ายเงิน"
                                className="w-full object-contain"
                              />
                            </div>
                            
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>


              <TabPanel value={"EditProduct"}>
                <EditProduct param_id={product_id} />
              </TabPanel>

              <TabPanel value={"EditUser"}>
                <EditUser param_id={user_id} />
              </TabPanel>

              <TabPanel value={"AddProduct"}>
                <AddProduct></AddProduct>
              </TabPanel>

              <TabPanel value={"Category"}>
                <Category></Category>
              </TabPanel>

              <TabPanel value={"Banner"}>
                <Banner></Banner>
              </TabPanel>

              <TabPanel value={"Website"}>
                <ConfigWebsite></ConfigWebsite>
              </TabPanel>

              <TabPanel value={"EditContact"}>
                <EditContact param_id={contact_id} />
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
export default AdminPage;
