import { Typography, styled } from "@mui/material";
import { useState, useEffect } from "react";
import 'aos/dist/aos.css';
import AOS from 'aos';

function Wishlist() {
  const Text20px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "12px",
    },
  }));
  const Text30px = styled(Typography)(({ theme }) => ({
    fontWeight:"600",
    [theme.breakpoints.up("xs")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "13px",
    },
  }));
  const products = [
    {
      id: 1,
      imageUrl: "/t1.jpg",
      alt: "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
      name: "AFFECTION TEE",
      price: "฿ 790",
    },
    {
      id: 2,
      imageUrl: "/t3.jpg",
      alt: "Lorem ipsum dolor sit description",
      name: "AFFECTION LS TEE",
      price: "฿ 1290",
    },
  ];
  const products1 = [
    {
      id: 1,
      imageUrl: "/image20.png",
      alt: "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
      name: "Lorem ipsum dolor sit amet",
      price: "฿ 590",
    },
    {
      id: 2,
      imageUrl: "/image20.png",
      alt: "Lorem ipsum dolor sit description",
      name: "Lorem ipsum dolor sit",
      price: "฿ 590",
    },
    {
      id: 3,
      imageUrl: "/image20.png",
      alt: "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
      name: "Lorem ipsum dolor sit amet",
      price: "฿ 590",
    },
    {
      id: 4,
      imageUrl: "/image20.png",
      alt: "Lorem ipsum dolor sit description",
      name: "Lorem ipsum dolor sit",
      price: "฿ 590",
    },
  ];
  const [count, setCount] = useState(1);

  const incrementCount = () => {
    setCount(count + 1); // เพิ่ม count ทีละ 1 หลังจากที่กดปุ่ม
  };
  const decrementCount = () => {
    if (count - 1 >= 1) {
      setCount(count - 1); // ลบ count ทีละ 1 หลังจากที่กดปุ่ม
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className=" w-full ">
      <div className="px-[5%] mt-5 " data-aos="fade-up" data-aos-duration="1000">
          <h1
            className=" text-sm font-semibold mb-5"
          >
            SHOPPING BAG
          </h1>
          <span
            className="text-[11px]  text-black mx-4"
          >
            FAVOURITES
          </span>
        </div>

      <div className="my-16 px-[5%] flex justify-center">
       
        <div className="mt-14 flex flex-wrap px-0">
          {/* <div className="w-full gap-4 justify-center flex flex-col md:flex-row overflow-auto md:px-20 px-0 gap-y-10 grid-cols-1 md:grid-cols-3 xl:grid-cols-4" data-aos="fade-up" data-aos-duration="1000">
            {products.map((product) => (
              <div key={product.id}>
                <div className="flex justify-center mt-2 md:mt-10 gap-4 md:gap-9">
                  <a href="/detail" className="group mb-10">
                    <div className="aspect-h-1 aspect-w-1 w-full md:h-[500px] relative lg:mt-0 mt-16 flex items-center justify-center rounded-lg  xl:aspect-h-8 xl:aspect-w-7">
                      <img
                        src={product.imageUrl}
                        alt={product.alt}
                        className="h-full flex relative w-full object-contain object-center group-hover:opacity-75"
                      />
                    </div>
                  </a>
                  <div>
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    >
                      <span
                        style={{ fontFamily: "Verdana, sans-serif" }}
                        className="sr-only"
                      >
                        Close menu
                      </span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                    <p className="flex justify-center mt-5">L</p>
                    <div className="flex justify-center mt-2">
                      <button
                        className="bg-white border border-gray-200 text-black flex justify-center font-bold py-2 px-4 w-7 "
                        onClick={incrementCount}
                      >
                        +
                      </button>
                    </div>
                    <button className="bg-white border border-gray-200 text-black flex justify-center font-bold py-2 px-4 w-7 ">
                      {count}
                    </button>
                    <button
                      className="bg-white border border-gray-200 text-black flex justify-center font-bold py-2 px-4 w-7 "
                      onClick={decrementCount}
                    >
                      -
                    </button>
                    <div className="flex justify-center mt-0 md:mt-5">
                      <input
                        id="link-checkbox"
                        type="checkbox"
                        value=""
                        className="w-6 h-6 justify-center text-black bg-gray-100 border-gray-300 rounded focus:ring-gray-300 dark:focus:ring-gray-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col text-center w-[85%]">
                  <h3 className="lg:text-xl text-[11px] mt-0 md:mt-4 text-black">
                    {product.name}
                  </h3>
                  <p className="mt-1 text-xl font-bold text-black ">
                    {product.price}
                  </p>
                </div>
              </div>
            ))}
          </div> */}

          <div className="flex justify-center md:justify-start px-[5%] w-full mt-12 mb-12">
            <div className="p-[5%] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700" data-aos="fade-up" data-aos-duration="1000">
              <div className="flex-col flex gap-6">
                <Text30px >
                  ORDER SUMMARY
                </Text30px>
                <div className=" flex justify-between">
                  <Text20px >
                    Subtotal
                  </Text20px>
                  <Text20px >
                    0฿
                  </Text20px>
                </div>

                <div className="flex justify-between">
                  <Text20px >
                    Shipping
                  </Text20px>
                  <Text20px >
                    0฿
                  </Text20px>
                </div>
                <hr />
                <div className="flex justify-between">
                  <Text20px >
                    TOTAL (TAX INCL.)
                  </Text20px>
                  <Text20px >
                    0฿
                  </Text20px>
                </div>
              </div>
              <form action="/payment">
                <div className="mt-4 flex flex-col gap-8">
                  <div className="flex items-center">
                    <input
                      required
                      id="link-checkbox"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 text-black bg-gray-100 border-gray-300 rounded focus:ring-gray-300 dark:focus:ring-gray-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="link-checkbox"
                      className="ms-2 text-xs font-medium text-gray-500 dark:text-gray-300"
                    >
                      I agree with the{" "}
                      <a
                        // style={{ fontFamily: "Verdana, sans-serif" }}
                        href="#"
                        className="text-gray-500 dark:text-blue-500 hover:underline text-xs"
                      >
                        terms and conditions
                      </a>
                      .
                    </label>
                  </div>
                  <button
                    type="submit"
                   
                    className="inline-flex items-center justify-center  w-[307px] h-[47.95px] px-3 py-2 text-xs font-medium text-center text-black bg-[#D9D9D9] hover:text-white transform duration-300 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Wishlist;
