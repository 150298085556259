import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import AdminPage from "./AdminPage";
import AdminLogin from "./AdminLogin";
import Cookies from "universal-cookie";

const cookies = new Cookies(null, { path: "/" });

const Admin = () => {
  //   const context: any = useOutletContext();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("role") === "djwoaijgeowaijigoewajgoewaigjewaio") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  return <>{isAdmin === true ? <AdminPage /> : <AdminLogin />}</>;
  // return (
  //   <>
  //     <AdminPage />
  //   </>
  // );
};

export default Admin;
