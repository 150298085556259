import React from "react";
interface MyComponentProps {
  props: string | null;
  name: string | null;
  size: string | null;
  count: number | null;
  price: string | null;
}
const ProductCard: React.FC<MyComponentProps> = ({
  props,
  name,
  size,
  count,
  price,
}) => {
  return (
    <div className=" bg-white border  lg:w-[600px]  border-gray-200  shadow dark:bg-gray-800 dark:border-gray-700">
      <a href="#">
        <img
          className="rounded-t-lg"
          src="/docs/images/blog/image-1.jpg"
          alt=""
        />
      </a>
      <div className="p-[5%] w-full mt-8 flex-col">
        <div>
          <a href="#">
            <h5 className="mb-2 lg:text-xl sm:text-sm tracking-tight text-gray-900 dark:text-white">
              YOUR ORDER
            </h5>
          </a>
        </div>
        <div className="flex gap-4 mt-9 ">
          {props && <img className="w-[140px] " src={props} alt="" />}
          <div className="flex flex-col gap-6">
            <h3 className="lg:text-[18px] text-sm font-bold">{name}</h3>
            <h3 className="lg:text-[15px]  text-sm font-bold">{size}</h3>
            <div className="flex justify-between gap-5 ">
              <h3 className="lg:text-[15px]  text-sm font-bold">({count})</h3>
              <h3 className="lg:text-[14px]  text-sm font-bold">{price} ฿</h3>
            </div>
          </div>
        </div>
        <hr className="mt-5" />
        <div className="mt-5 flex justify-between">
          <h3 className="text-[15px] font-medium">Subtotal</h3>
          <h3 className="text-[15px] font-medium">{price} ฿</h3>
        </div>
        <hr className="mt-5" />
        <div className="mt-5 flex justify-between">
          <h3 className="text-[15px] font-medium">Shipping</h3>
          <h3 className="text-[15px] font-medium">Calculated at next step</h3>
        </div>
        <hr className="mt-5" />
        <div className="mt-5 flex justify-between">
          <h3 className="text-[15px] font-medium">Total</h3>
          <h3 className="text-[15px] font-medium">{price} ฿</h3>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
