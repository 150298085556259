import { useEffect, useState } from "react";
// import logo from "./logo.svg";
import "./App.css";
import { useLocation, Outlet } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import Navbar from "./Layout/Navbar";
import Footer from "./Layout/Footer";
// import { render } from "@testing-library/react";

function App() {
  const { pathname } = useLocation();
  const [showNavbar, setShowNavbar] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const defaultTheme = createTheme({
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
    },
    palette: {
      primary: {
        main: "#FFFFF",
      },
      secondary: {
        main: "#16171B",
      },
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          colorPrimary: {
            color: "#FFFFF",
          },
        },
      },
    },
  });
  // useEffect(() => {
  //   const noNavbarPaths = ["/login"];
  //   setShowNavbar(!noNavbarPaths.includes(pathname));
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  useEffect(() => {
    const noNavbarFooterPaths = ["/Login", "/Register"];
    setShowNavbar(!noNavbarFooterPaths.includes(pathname));
    setShowFooter(!noNavbarFooterPaths.includes(pathname));
    window.scrollTo(0, 0);
  }, [pathname]);

 

  return (
    <ThemeProvider theme={defaultTheme}>
      <div className=" mx-auto ">
        {showNavbar && <Navbar />}

        <main>
          <Outlet />
        </main>
      </div>
      {showFooter && <Footer />}
      
    </ThemeProvider>
  );
}

export default App;