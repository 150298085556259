import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './Page/Home';
import Shop from './Page/Shop';
import Detail from './Page/Detail';
import Cart from './Page/Cart';
import Shipping from './Page/Shipping';
import Lookbook from './Page/Lookbook';
import Contact from './Page/Contact';
import Wishlist from './Page/Wishlist';
import Payment from './Page/Payment';
import Checkout from './Page/Checkout';
import Information from './Page/Information';
import Complete from './Page/Complete';
import Admin from './Page/admin/Admin';
import Register from './Page/Register';
import Login from './Page/Login';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter([
  // { path: '/login', element: <Login /> },
  // { path: '/loginbackoffice', element: <LoginBackOffice /> },
  // { path: '/register', element: <Register /> },
  // { path: '/backoffice', element: <BackOffice /> },

  {
    element: <App />,
    children: [
      { path: "/", element: <Home /> },
      { path: '/shop', element: <Shop /> },
      { path: '/detail/:id', element: <Detail /> },
      { path: '/cart', element: <Cart /> },
      { path: '/Lookbook', element: <Lookbook /> },
      { path: '/Contact', element: <Contact /> },
      { path: '/wishlist', element: <Wishlist /> },
      { path: '/payment', element: <Payment /> },
      { path: '/Detail', element: <Detail /> },
      { path: '/checkout', element: <Checkout /> },
      { path: '/information', element: <Information /> },
      { path: '/complete', element: <Complete /> },
      { path: '/admin', element: <Admin /> },
   
      { path: '/Login', element: <Login /> },
      { path: '/Register', element: <Register /> },

      { path: '/Shipping', element: <Shipping setValue={function (value: React.SetStateAction<number>): void {
        throw new Error('Function not implemented.');
      } } /> },
     

      // { path: '/productcatalog', element: <Product_Catalog /> },
      // { path: '/cart', element: <Cart /> },
      // { path: '/product/:id', element: <ProductDetail /> },
      // { path: '/checkout', element: <CheckOut /> },
      // { path: '/selectaddress', element: <SelectAddress /> },
      // { path: '/ourproducts', element: <OurProducts /> },
      // { path: '/sustainability', element: <Sustainability /> },
      // { path: '/contact', element: <Contactus /> },
      // { path: '/petfood/:id', element: <PetFood /> },
      
    ],
  },

]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
