import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";

function Login() {
  const [showNavbar, setShowNavbar] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const toggleVisibility = () => {
    setShowNavbar(!showNavbar);
    setShowFooter(!showFooter);
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("role") !== null) {
      return navigate("/");
    }
  });

  const cookies = new Cookies(null, { path: "/" });
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");

  const loginNow = async () => {
    if (
      Email != null &&
      Email !== "" &&
      Email !== undefined &&
      Password != null &&
      Password !== "" &&
      Password !== undefined
    ) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/members/member/login/`, {
          email: Email,
          password: Password,
        })
        .then((response) => {
          if (response.data.message === "Logged in") {
            cookies.set("token", response.data.token);
            sessionStorage.setItem("role", "user");

            Swal.fire({
              title: "เข้าสู่ระบบสำเร็จ",
              confirmButtonColor: "#202223",
              icon: "success",
            }).then(() => {
              return navigate("/");
            });
          } else {
            Swal.fire({
              title: "เข้าสู่ไม่ระบบสำเร็จ",
              text: `${response.data.message}`,
              confirmButtonColor: "#202223",
              icon: "error",
            });
          }
        });
    } else {
      Swal.fire({
        title: "เข้าสู่ไม่ระบบสำเร็จ",
        text: `กรุณากรอกข้อมูลให้ครบถ้วน`,
        confirmButtonColor: "#202223",
        icon: "error",
      });
    }
  };

  return (
    <div className="flex items-center min-h-screen p-4 bg-gray-500 lg:justify-center">
      <div className="flex flex-col overflow-hidden bg-white rounded-md shadow-lg max md:flex-row md:flex-1 lg:max-w-screen-md">
        <div className=" text-white  md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly">
          <div className=" text-4xl font-bold tracking-wider text-center bg-[#c9c9cf]">
            <img
              className="w-[4500px] h-[450px] object-contain md:object-cover"
              src="/Talmid-6.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="pt-3 px-3 bg-white h-[450px] md:flex-1">
          <div className="flex justify-end">
            <a href="/">
              <button
                type="button"
                className="bg-white rounded-md inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset"
              >
                <span
                  className="sr-only"
                >
                  Close menu
                </span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </a>
          </div>
          <h3
            className="flex justify-center my-2 md:text-2xl text-gray-700 font-bold"
          >
            Sign in
          </h3>
          <p
            className="flex items-center text-xs justify-center text-center mb-5"
          >
            SUBSCRIBE TO RECEIVE<br />THE LATEST TALMID DROP NEWS
          </p>
          <div className="flex flex-col space-y-2">
            <div className="flex flex-col space-y-1">
              <label
                htmlFor="email"
                className="text-sm font-semibold text-gray-500"
              ></label>
              <input
                placeholder="Email"
                type="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="text"
                className="text-sm font-semibold text-gray-500"
              ></label>
              <input
                placeholder="Password"
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
              />
            </div>
            <div>
              <Button
                sx={{ bgcolor: "#202223", color: "white" }}
                onClick={() => loginNow()}
                className="w-full mt-3 px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-[#202223] rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
              >
                Login
              </Button>
              <div>
                <p
                  className="flex text-center justify-center mt-3  text-[12px]"
                >
                  {/* Are you already a member? */}
                  You are not a member?{" "}
                  <a href="/Register" className=" text-blue-500">
                    {" "}
                    Sign up
                  </a>
                </p>
              </div>
              <p
                className="flex text-center justify-center mt-3 pb-3 text-[10px]"
              >
                By signing up, you agree to receive marketing <br />
                emails. View our privacy policy and terms of <br />
                service for more info.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
