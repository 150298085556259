import { Typography, styled } from "@mui/material";
import React, { useState } from "react";
import Alway from "../components/Alway";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";

function Checkout() {
  const Text20px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "14px",
    },
  }));
  const Text30px = styled(Typography)(({ theme }) => ({
    fontWeight:"600",
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "15px",
    },
  }));
  const [count, setCount] = useState(1);

  const incrementCount = () => {
    setCount(count + 1); // เพิ่ม count ทีละ 1 หลังจากที่กดปุ่ม
  };
  const decrementCount = () => {
    if (count - 1 >= 1) {
      setCount(count - 1); // ลบ count ทีละ 1 หลังจากที่กดปุ่ม
    }
  };
  const navigate = useNavigate();
  const cookies = new Cookies(null, { path: "/" });

  const [PName, setPName] = React.useState("");
  const [PPrice, setPPrice] = React.useState(1);
  const [img, setImg] = React.useState("");
  const [Cold, setCold] = React.useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pid = searchParams.get("pid");
  const oid = searchParams.get("oid");

  const fetchProduct = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/products/id/${pid}`)
      .then((response) => {
        const data = response.data;
        setPName(data.product[0].name);
        setPPrice(data.priceOfProduct[0].price);
        const dataImg = data.imageOfProduct[0].path;
        setImg(dataImg.replace("{api_path}", process.env.REACT_APP_API_URL));
      });
  };
  if (Cold === false) {
    fetchProduct();
    setCold(true);
  }

  const orderNow = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/orders/updateOrder/`,
        {
          id: oid,
          price: PPrice * count + 50,
          piece: count,
        },
        {
          headers: {
            token: cookies.get("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.message === "OK") {
          return navigate(`/payment?price=${PPrice * count + 50}&oid=${oid}`);
        }
      });
  };

  return (
    <div className="inline justify-center w-full">
      <div className="w-full px-[2%] py-[1%] flex justify-between border-t-2">
        <a href="/information" className=" text-sm font-semibold">
          <button>
            <FontAwesomeIcon icon={faAngleLeft} className="mx-2 " />
            BACK TO INFORMATION
          </button>
        </a>
      </div>
      <div className="my-12 px-[5%]">
        <div>
          <h1
            
            className=" text-sm font-semibold mb-5"
          >
            CHECKOUT
          </h1>
          <span
            
            className="text-sm font-semibold text-gray-500 mr-4"
          >
            INFORMATION
          </span>
          <span
            
            className="text-sm font-semibold text-black mx-4"
          >
            SHOPPING
          </span>
          <span
            
            className="text-sm font-semibold text-gray-500 mx-4"
          >
            PAYMENT
          </span>
        </div>
        <div className="mt-14 flex flex-wrap">
          <div className="w-full gap-4 justify-center flex flex-col overflow-auto md:px-20 px-0 gap-y-10 grid-cols-1 md:grid-cols-3 xl:grid-cols-4">
            <div className="flex items-center justify-center mt-2 md:mt-10 gap-4 md:gap-9">
              <div className="group">
                <div className="aspect-h-1 aspect-w-1 w-full md:h-[500px] relative lg:mt-0 mt-16 flex items-center justify-center rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <img
                    src={img}
                    alt=""
                    className="h-full flex relative w-full object-contain object-center group-hover:opacity-75"
                  />
                </div>
              </div>
              <div>
                {/* <button
                  type="button"
                  className="bg-white rounded-md inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span
                    style={{ fontFamily: "Verdana, sans-serif" }}
                    className="sr-only"
                  >
                    Close menu
                  </span>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button> */}
                {/* <p className="flex justify-center mt-5">L</p> */}
                <div className="flex justify-center mt-2">
                  <button
                    className="bg-white border border-gray-200 text-black flex justify-center font-bold py-2 px-4 w-7 "
                    onClick={incrementCount}
                  >
                    +
                  </button>
                </div>
                <button className="bg-white border border-gray-200 text-black flex justify-center font-bold py-2 px-4 w-7 ">
                  {count}
                </button>
                <button
                  className="bg-white border border-gray-200 text-black flex justify-center font-bold py-2 px-4 w-7 "
                  onClick={decrementCount}
                >
                  -
                </button>
                {/* <div className="flex justify-center mt-0 md:mt-5">
                  <input
                    id="link-checkbox"
                    type="checkbox"
                    value=""
                    className="w-6 h-6 justify-center text-black bg-gray-100 border-gray-300 rounded focus:ring-gray-300 dark:focus:ring-gray-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </div> */}
              </div>
            </div>
            <div className="flex flex-col text-center w-full">
              <h3 className="lg:text-xl text-[11px] text-black">{PName}</h3>
              <p className="mt-1 text-xl font-bold text-black ">{PPrice}</p>
            </div>
          </div>

          <div className="flex justify-center md:justify-start px-[5%] w-full mt-12">
            <div className="p-[5%] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <div className="flex-col flex gap-6">
                <Text30px>ORDER SUMMARY</Text30px>
                <div className=" flex justify-between">
                  <Text20px>Subtotal</Text20px>
                  <Text20px>{PPrice * count} ฿</Text20px>
                </div>

                <div className="flex justify-between">
                  <Text20px>Shipping</Text20px>
                  <Text20px>50฿</Text20px>
                </div>
                <hr />
                <div className="flex justify-between">
                  <Text20px>TOTAL (TAX INCL.)</Text20px>
                  <Text20px>{PPrice * count + 50} ฿</Text20px>
                </div>
              </div>
              <form action="/payment">
                <div className="mt-4 flex flex-col gap-8">
                  <div className="flex items-center">
                    <input
                      required
                      id="link-checkbox"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 text-black bg-gray-100 border-gray-300 rounded focus:ring-gray-300 dark:focus:ring-gray-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="link-checkbox"
                      className="ms-2 text-sm font-medium text-gray-500 dark:text-gray-300"
                    >
                      I agree with the{" "}
                      <a
                        href="#"
                        className="text-gray-500 dark:text-blue-500 hover:underline"
                      >
                        terms and conditions
                      </a>
                      .
                    </label>
                  </div>
                  <button
                    type="button"
                    onClick={() => orderNow()}
                    className="inline-flex items-center justify-center  w-[307px] h-[47.95px] px-3 py-2 text-sm font-medium text-center text-black bg-[#D9D9D9]  hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
