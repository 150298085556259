import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faBagShopping,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
// import Modal from 'react-modal';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  padding: 0,
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
  />
))(({ theme }) => ({
  padding: 0,
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingInline: theme.spacing(2),
}));

const Img = styled("img")(({ theme }) => ({}));
const Text50px = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "12px",
  },
}));
const Text30px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "12px",
  },
}));

function Detail() {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const [product, setProduct] = useState<any>(null);
  const [size, setSize] = useState<any>("S");
  const [images, setImages] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState(
    images.length > 0 ? images[0] : ""
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState("inchs");
  const handleUnitChange = (unit: string) => {
    setSelectedUnit(unit);
  };

  useEffect(() => {
    if (!id) {
      navigate("/shop");
      return;
    }

    const fetchProduct = async () => {
      try {
        const response = await fetch(
          `https://api.talmidwear.com/products/id/${id}`
        );
        const data = await response.json();
        if (data && data.product) {
          const productWithPrice = {
            ...data.product[0],
            priceOfProduct: data.priceOfProduct,
          };
          setProduct(productWithPrice);
        } else {
          navigate("/shop");
        }
      } catch (error) {
        console.error("Error fetching product:", error);
        navigate("/shop");
      }
    };

    const fetchImages = async () => {
      try {
        const response = await fetch(
          `https://api.talmidwear.com/products/pathAllImg/${id}`
        );
        const data = await response.json();
        if (data && data.data) {
          const imagePaths = Object.values(data.data[0])
            .filter(
              (path): path is string =>
                typeof path === "string" && path !== null
            )
            .map((path) =>
              path.replace("{api_path}", "https://api.talmidwear.com")
            );
          setImages(imagePaths);
          setSelectedImage(imagePaths.length > 0 ? imagePaths[0] : "");
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchProduct();
    fetchImages();
  }, [id, navigate]);

  if (!product) {
    return null;
  }

  const nextProductId = id ? parseInt(id) + 1 : 1;

  return (
    <div className="flex justify-center">
      <div className="flex-col flex w-full ">
        <div className="w-full px-[2%] py-[1%] flex justify-between border-2">
          <a href="/shop">
            <button className="text-[11px] font-semibold">
              <FontAwesomeIcon icon={faAngleLeft} className="mx-2 " />
              BACK TO SHOP
            </button>
          </a>
          <a href={`/detail/${nextProductId}`}>
            <button className="text-[11px] font-semibold">
              NEXT PRODUCT
              <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
            </button>
          </a>
        </div>
        <div className="flex flex-col md:flex-row mb-14 relative px-[5%] py-20">
          <div
            className="flex flex-col items-center bg-beige p-4 md:1/2"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div
              onClick={() => setIsModalOpen(true)}
              className="flex justify-center mb-4 w-full max-w-2xl h-auto relative overflow-hidden cursor-pointer"
            >
              <InnerImageZoom
                src={selectedImage}
                zoomSrc={selectedImage}
                zoomType="hover"
                zoomPreload={true}
              />
            </div>

            <div className="flex overflow-x-auto space-x-1">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index}`}
                  className={`h-24 cursor-pointer transition-transform duration-300 ${
                    selectedImage === image ? "border-2 border-blue-500" : ""
                  }`}
                  onClick={() => setSelectedImage(image)}
                />
              ))}
            </div>
          </div>

          {/* Detail start */}
          <div
            className="flex-col px-[4%] h-full w-full md:w-1/2 lg:mx-6 flex gap-4 mt-5 md:mt-0"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="mt-10">
              <div className="mb-3">
                <Text50px>{product.name}</Text50px>
              </div>

              <div className="flex-col">
                <div>
                  <Text30px>
                    ฿
                    {product.priceOfProduct && product.priceOfProduct.length > 0
                      ? parseFloat(
                          product.priceOfProduct[0].price
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </Text30px>
                </div>
                <div className="flex mt-4">
                  <div
                    className="inline-flex rounded-md shadow-sm "
                    role="group"
                  >
                    {product.category_id !== 33
                      ? ["S", "M", "L", "XL"].map((size) => (
                          <button
                            key={size}
                            type="button"
                            className="px-4 py-2 text-xs font-semibold text-gray-900 hover:bg-gray-200 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                          >
                            {size}
                          </button>
                        ))
                      : ["M", "L", "XL"].map((size) => (
                          <button
                            key={size}
                            type="button"
                            className="px-4 py-2 text-xs font-semibold text-gray-900 hover:bg-gray-200 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                          >
                            {size}
                          </button>
                        ))}
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="mt-4">
                  <Accordion className="custom-accordion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className="custom-accordion-summary"
                    >
                      <Text50px className="custom-accordion-title">
                        DETAILS
                      </Text50px>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        className="whitespace-pre-line text-xs"
                        dangerouslySetInnerHTML={{
                          __html: product.description,
                        }}
                      ></div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="mt-4">
                  <Accordion className="custom-accordion">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className="custom-accordion-summary"
                    >
                      <Text50px className="custom-accordion-title">
                        SIZE GUIDE
                      </Text50px>
                    </AccordionSummary>
                    {/* <AccordionDetails>
                      <div className="whitespace-pre-line text-xs">
                        {product.size}
                      </div>
                      <div className=" flex">
                        <p className="text-xs font-semibold"> CM &nbsp;&nbsp;</p>
                        <span className=" font-bold text-lg mr-2 align-top -mt-2">
                          |
                        </span>
                        <p className="text-xs font-semibold"> INCHS</p>
                      </div>
                      <div className="mt-4 justify-center flex">
                        <img
                          src="/size.jpg"
                          className="w-full md:w-[90%]"
                          alt=""
                        />
                      </div>
                    </AccordionDetails> */}
                    <AccordionDetails>
                      <div className="whitespace-pre-line text-xs">
                        {product.size}
                      </div>
                      <div className="flex">
                        <span className="font-bold text-lg mr-2 align-top -mt-2">
                          |
                        </span>
                        <p className="text-xs font-semibold">INCHES</p>
                      </div>

                      {/* ตารางสำหรับ "เสื้อ" */}
                      {product.category_id === 30 && (
                        <div className="overflow-x-auto mt-4">
                          <table className="min-w-full text-center text-xs md:text-sm border-collapse">
                            <thead>
                              <tr className="border-t border-b">
                                <th className="px-2 py-3 text-start w-[15%]">
                                  SIZE:
                                </th>
                                <th className="px-2 py-3">S</th>
                                <th className="px-2 py-3">M</th>
                                <th className="px-2 py-3">L</th>
                                <th className="px-2 py-3">XL</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="border-b">
                                <td className="px-2 py-3 font-semibold text-start">
                                  CHEST
                                </td>
                                <td className="px-2 py-3">40</td>
                                <td className="px-2 py-3">44</td>
                                <td className="px-2 py-3">48</td>
                                <td className="px-2 py-3">52</td>
                              </tr>
                              <tr className="border-b">
                                <td className="px-2 py-3 font-semibold text-start">
                                  LENGTH
                                </td>
                                <td className="px-2 py-3">27.5</td>
                                <td className="px-2 py-3">29</td>
                                <td className="px-2 py-3">30.5</td>
                                <td className="px-2 py-3">32</td>
                              </tr>
                              <tr className="border-b">
                                <td className="px-2 py-3 font-semibold text-start">
                                  SLEEVE
                                </td>
                                <td className="px-2 py-3">9</td>
                                <td className="px-2 py-3">9.5</td>
                                <td className="px-2 py-3">10</td>
                                <td className="px-2 py-3">10.5</td>
                              </tr>
                              <tr className="border-b">
                                <td className="px-2 py-3 font-semibold text-start">
                                  SHOULDER
                                </td>
                                <td className="px-2 py-3">17</td>
                                <td className="px-2 py-3">19</td>
                                <td className="px-2 py-3">21</td>
                                <td className="px-2 py-3">23</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                      {/* ตารางสำหรับ "เสื้อแขนยาว" */}
                      {product.category_id === 32 && (
                        <div className="overflow-x-auto mt-4">
                          <table className="min-w-full text-center text-xs md:text-sm border-collapse">
                            <thead>
                              <tr className="border-t border-b">
                                <th className="px-2 py-3 text-start w-[15%]">
                                  SIZE:
                                </th>
                                <th className="px-2 py-3">S</th>
                                <th className="px-2 py-3">M</th>
                                <th className="px-2 py-3">L</th>
                                <th className="px-2 py-3">XL</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="border-b">
                                <td className="px-2 py-3 font-semibold text-start">
                                  CHEST
                                </td>
                                <td className="px-2 py-3">40</td>
                                <td className="px-2 py-3">44</td>
                                <td className="px-2 py-3">48</td>
                                <td className="px-2 py-3">52</td>
                              </tr>
                              <tr className="border-b">
                                <td className="px-2 py-3 font-semibold text-start">
                                  LENGTH
                                </td>
                                <td className="px-2 py-3">27.5</td>
                                <td className="px-2 py-3">29</td>
                                <td className="px-2 py-3">30.5</td>
                                <td className="px-2 py-3">32</td>
                              </tr>
                              <tr className="border-b">
                                <td className="px-2 py-3 font-semibold text-start">
                                  SLEEVE
                                </td>
                                <td className="px-2 py-3">24</td>
                                <td className="px-2 py-3">25</td>
                                <td className="px-2 py-3">26</td>
                                <td className="px-2 py-3">27</td>
                              </tr>
                              <tr className="border-b">
                                <td className="px-2 py-3 font-semibold text-start">
                                  SHOULDER
                                </td>
                                <td className="px-2 py-3">17</td>
                                <td className="px-2 py-3">19</td>
                                <td className="px-2 py-3">21</td>
                                <td className="px-2 py-3">23</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                      {/* ตารางสำหรับ "เสื้อฟอก" */}
                      {product.category_id === 33 && (
                        <div className="overflow-x-auto mt-4">
                          <table className="min-w-full text-center text-xs md:text-sm border-collapse">
                            <thead>
                              <tr className="border-t border-b">
                                <th className="px-2 py-3 text-start w-[15%]">
                                  SIZE:
                                </th>
                                <th className="px-2 py-3">M</th>
                                <th className="px-2 py-3">L</th>
                                <th className="px-2 py-3">XL</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="border-b">
                                <td className="px-2 py-3 font-semibold text-start">
                                  CHEST
                                </td>
                                <td className="px-2 py-3">44</td>
                                <td className="px-2 py-3">48</td>
                                <td className="px-2 py-3">52</td>
                              </tr>
                              <tr className="border-b">
                                <td className="px-2 py-3 font-semibold text-start">
                                  LENGTH
                                </td>
                                <td className="px-2 py-3">29</td>
                                <td className="px-2 py-3">30</td>
                                <td className="px-2 py-3">31</td>
                              </tr>
                              <tr className="border-b">
                                <td className="px-2 py-3 font-semibold text-start">
                                  SLEEVE
                                </td>
                                <td className="px-2 py-3">9</td>
                                <td className="px-2 py-3">9.5</td>
                                <td className="px-2 py-3">10</td>
                              </tr>
                              <tr className="border-b">
                                <td className="px-2 py-3 font-semibold text-start">
                                  SHOULDER
                                </td>
                                <td className="px-2 py-3">23</td>
                                <td className="px-2 py-3">24</td>
                                <td className="px-2 py-3">25</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                      {/* <div className="mt-4 justify-center flex">
                    <img
                      src="/t-shrit1.jpeg"
                      className="w-full md:w-[50%]"
                      alt=""
                    />
                  </div> */}
                      <div className="mt-4 justify-center flex">
                        <img
                          src={
                            product.category_id === 32
                              ? "/t-shrit3.jpeg"
                              : "/t-shrit1.jpeg"
                          }
                          className="w-full md:w-[50%]"
                          alt=""
                        />
                      </div>
                    </AccordionDetails>

                    {/* <AccordionDetails>
                    <div className="whitespace-pre-line text-xs">
                      {product.size}
                    </div>
                    <div className="flex">
                      {id !== "135" && (
                        <p
                          className={`text-xs font-semibold cursor-pointer ${
                            selectedUnit === "inchs"
                              ? "text-black"
                              : "text-gray-500"
                          }`}
                          onClick={() => handleUnitChange("inchs")}
                        >
                          INCHS
                        </p>
                      )}
                      <span className="font-bold text-lg mr-2 align-top -mt-2">|</span>
                      <p
                        className={`text-xs font-semibold cursor-pointer ${selectedUnit === "inchs" ? "text-black" : "text-gray-500"}`}
                        onClick={() => handleUnitChange("inchs")}
                      >
                        INCHS
                      </p>
                    </div>
                    <div className="mt-4 justify-center flex">
                      <img
                        src={
                          id === "135"
                            ? "/size.jpg"
                            : selectedUnit === "cm"
                            ? "/sizecm.PNG"
                            : "/sizeinchs.PNG"
                        }
                        className="w-full md:w-[90%]"
                        alt="Size guide"
                      />
                    </div>
                  </AccordionDetails> */}
                  </Accordion>
                </div>
                <div className="flex flex-col">
                  <div className="mt-4">
                    <Accordion className="custom-accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        className="custom-accordion-summary"
                      >
                        <Text50px className="custom-accordion-title">
                          Shipping & Returns
                        </Text50px>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="text-xs">
                          {/* {product.shipping} */}
                          Standard 50 THB (3-5 business day)
                        </div>
                        <div className="text-xs">
                          *Free Shipping for orders over 1,000 THB
                        </div>
                        <div className="text-xs">
                          *We do not offer returns or exchanges
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div className="mt-8 flex gap-4">
                    <a
                      href={`/information?id=${id}&size=${size}`}
                      className="inline-flex h-12 justify-center items-center w-1/2 px-3 py-2 text-sm font-medium text-center transform duration-300 text-[#D9D9D9] bg-[#323334] hover:bg-[#D9D9D9] hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      ADD TO BAG
                      <FontAwesomeIcon
                        icon={faBagShopping}
                        className="w-5 h-5 mx-2"
                      />
                    </a>
                    <a
                      href="/wishlist"
                      className="inline-flex h-12 justify-center items-center w-1/2 px-3 py-2 text-xs font-medium text-center transform duration-300 text-black bg-[#D9D9D9] hover:bg-black hover:text-red-500 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      WISHLIST
                      <FontAwesomeIcon
                        icon={faHeart}
                        className="w-5 h-5 mx-2"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Detail end */}
        </div>
      </div>
      {/* <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            background: "none", // ลบพื้นหลังสีขาว
            border: "none", // ลบเส้นขอบ
            padding: "0", // ลบ padding
          },
        }}
      >
        <div className="flex justify-center">
          <img src={selectedImage} alt="Selected" className="md:w-[80%]" />
        </div>
      </Modal> */}
    </div>
  );
}

export default Detail;
