import {
  AccountCircle,
  CloudUploadOutlined,
  DeleteOutline,
  EditOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "universal-cookie";
import YouTube from "react-youtube";

const Banner = () => {
  const [fileImg, setFileImg] = useState<any>(undefined);
  const cookies = new Cookies(null, { path: "/" });
  const navigate = useNavigate();
  const [link, setLink] = useState("");

  const handleUpload = (event: any) => {
    if (event.target.files[0] === undefined) {
      return;
    }

    setFileImg(event.target.files[0]);
  };

  const uploadBanner = async () => {
    if (fileImg !== undefined) {
      const formData = new FormData();
      formData.append("file", fileImg);

      await axios
        .post(`${process.env.REACT_APP_API_URL}/upload/productImg`, formData, {
          headers: {
            token: cookies.get("token"),
          },
        })
        .then(async (response) => {
          // console.log(response);
          if (response.data.message == "OK") {
            Swal.fire({
              title: "แก้ไขเรียบร้อยแล้ว",
              icon: "success",
            });
            await axios.post(
              `${process.env.REACT_APP_API_URL}/banner/insertpathimg`,
              { path_img: response.data.path },
              {
                headers: {
                  token: cookies.get("token"),
                },
              }
            );
            const timer = setTimeout(() => {
              window.location.reload();
            }, 3000);

            return () => clearTimeout(timer);
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: `แก้ไขไม่สำเร็จ (${response.data.message})`,
              icon: "error",
            });
          }
        });
    }
  };

  const linkChange = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/banner/insertLink`,
        { link: link },
        {
          headers: {
            token: cookies.get("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.message == "OK") {
          Swal.fire({
            title: "แก้ไขเรียบร้อยแล้ว",
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "เกิดข้อผิดพลาด",
            text: `แก้ไขไม่สำเร็จ (${response.data.message})`,
            icon: "error",
          });
        }
      });
  };

  useEffect(() => {}, []);
  interface VideoPlayerProps {
    url: string;
  }

  const VideoPlayer: React.FC<VideoPlayerProps> = ({ url }) => {
    const getVideoId = (url: string) => {
      const urlObj = new URL(url);
      return urlObj.searchParams.get("v");
    };

    const videoId = getVideoId(url);

    const opts = {
      height: "390",
      width: "640",
    };

    return videoId ? (
      <YouTube videoId={videoId} opts={opts} />
    ) : (
      <div>Invalid URL</div>
    );
  };

  const [url, setUrl] = useState("");

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <Stack direction="row" alignItems="center">
          <input
            accept="image/*"
            id="upload-photo"
            type="file"
            style={{ display: "none" }}
            onChange={handleUpload}
          />
          <label htmlFor="upload-photo">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadOutlined />}
            >
              Upload Photo
            </Button>
          </label>

          <Typography variant="body2" color="textSecondary">
            &nbsp;&nbsp;Maximum file size: 5MB
          </Typography>
        </Stack>
      </Grid>
      <Grid item md={6}>
        <Button
          onClick={() => {
            uploadBanner();
          }}
          fullWidth
          variant={"contained"}
          size={"large"}
        >
          + เพิ่ม Banner
        </Button>
      </Grid>
      {/* <Grid item sm={12} md={12}>
        <VideoPlayer url={url} />
      </Grid> */}
      {/* <Grid item sm={6} md={6}>
        <FormControl fullWidth>
          <TextField
            id="standard-bare"
            variant="outlined"
            placeholder="Youtube link..."
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
            }}
            InputProps={{
              sx: {
                height: "45px",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-input": {
                py: "8px",
                fontSize: "14px",
              },
            }}
          />
        </FormControl>
      </Grid>
      <Grid item md={6}>
        <Button
          onClick={() => {
            linkChange();
          }}
          fullWidth
          variant={"contained"}
          size={"large"}
        >
          เปลี่ยนวิดีโอ
        </Button>
      </Grid> */}
    </Grid>
  );
};
export default Banner;
