// import React from "react";
// import logo from "../assets/logo.jpg";
// import { Divider, Grid, styled } from "@mui/material";
// import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFacebookF,
//   faInstagram,
//   faLine,
//   faYoutube,
// } from "@fortawesome/free-brands-svg-icons";

// type Props = {};
// const Img = styled("img")(({ theme }) => ({}));
// const LinkMenu = styled(Link)(({ theme }) => ({
//   textDecoration: "none",
//   color: "#000",
// }));

// const images = ["/facebook.png", "/instagram.png", "/clothblack.png"];
// function Footer({}: Props) {
//   return (
//     <div>
//       <div className="w-full border border-[#3B3B3B] md:mt-0 mt-12" />
//       <div className="px-[5%] pt-4">
//         <footer className="md:flex inline md:justify-normal justify-center p-4 dark:bg-gray-800">
//           <a
//             href="/"
//             target="_blank"
//             className="flex items-center mb-0 justify-center"
//           >
//             <Img src="/LOGO_Talmid.png" className="w-[300px] h-[120px]" />
//           </a>
//           <div className="inline w-full p-[2%]">
//             <div className="flex px-0 md:px-[10%]">
//               <ul className="flex md:pb-[5%] w-full">
//                 <div className="md:flex inline space-y-4 md:space-y-0">
//                   <li>
//                     <a
//                       href="/shop"
//                       className="mr-12 text-[16px] font-bold text-black hover:underline dark:text-gray-400"
//                     >
//                       SHOP
//                     </a>
//                   </li>
//                   <li>
//                     <a
//                       href="/Lookbook"
//                       className="mr-12 text-[16px] font-bold text-black hover:underline dark:text-gray-400"
//                     >
//                       LOOKBOOK
//                     </a>
//                   </li>
//                 </div>
//                 <div className="md:flex inline space-y-4 md:space-y-0">
//                   <li>
//                     <a
//                       href="/contact"
//                       className="mr-12 text-[16px] font-bold text-black hover:underline dark:text-gray-400"
//                     >
//                       CONTACT
//                     </a>
//                   </li>
//                   <li className="">
//                     <a
//                       href="#"
//                       className="text-[16px] font-bold text-black hover:underline dark:text-gray-400"
//                     >
//                       KEEP IN TOUCH
//                     </a>
//                   </li>
//                 </div>
//               </ul>
//             </div>
//             <div className="flex w-full justify-end my-4 md:space-y-0 md:flex-row">
//               <input
//                 id="email"
//                 type="text"
//                 className=" px-4 py-2  text-gray-700 bg-white border dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 focus:ring-blue-300"
//                 placeholder="Email Address"
//               />
//               <button className="w-full h-11 px-6 py-2.5 text-sm font-medium tracking-wider text-white transition-colors duration-300 transform md:w-auto focus:outline-none bg-black hover:bg-gray-700 focus:ring focus:ring-gray-300 focus:ring-opacity-80">
//                 JOIN
//               </button>
//             </div>
//           </div>
//         </footer>
//       </div>
//       <div className="bg-[#3d3d3d] flex w-full px-[5%]">
//         <div className="flex items-center justify-between h-full w-full">
//           <div className=" flex w-1/2 items-center">
//             <div>
//               <a href="#">
//                 <FontAwesomeIcon
//                   icon={faInstagram}
//                   className="w-8 h-8 rounded-full p-1 md:p-3 text-white"
//                 />
//               </a>
//             </div>
//             <div>
//               <a href="#">
//                 <FontAwesomeIcon
//                   icon={faFacebookF}
//                   className="w-8 h-8 rounded-full p-1 md:p-3 text-white"
//                 />
//               </a>
//             </div>
//             <div>
//               <a href="#">
//                 <FontAwesomeIcon
//                   icon={faYoutube}
//                   className="w-8 h-8 rounded-full p-1 md:p-3 text-white"
//                 />
//               </a>
//             </div>
//             <div>
//               <a href="#">
//                 <FontAwesomeIcon
//                   icon={faLine}
//                   className="w-8 h-8 rounded-full p-1 md:p-3 text-white"
//                 />
//               </a>
//             </div>
//           </div>
//           <div className="w-1/2 flex justify-end">
//             <h1 className="text-sm md:text-base text-white">
//               Copyright ©2024 All Rights Reseved.
//             </h1>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Footer;

import React,{ useEffect } from "react";
import logo from "../assets/logo.jpg";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLine,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import 'aos/dist/aos.css';
import AOS from 'aos';

const Img = styled("img")(({ theme }) => ({}));
const LinkMenu = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "#000",
}));

function Footer() {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div >
      <div className="w-full border-b border-gray-300 mt-12" />
      <div className="px-6 py-8" >
        <footer className="flex flex-col lg:flex-row items-center justify-between">
          <a href="/" target="_blank" className="mb-4 md:mb-0" >
            <Img src="/LOGO_Talmid.png" className=" w-56 h-28 object-cover" />
          </a>
          <div className="flex flex-col md:flex-row items-center justify-center md:space-x-12 flex-1 mb-5" >
            <ul className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-12">
              <li>
                <a
                  href="/shop"
                  className=" text-xs font-bold text-black hover:underline"
                >
                  SHOP
                </a>
              </li>
              <li>
                <a
                  href="/Lookbook"
                  className=" text-xs font-bold text-black hover:underline"
                >
                  LOOKBOOK
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className=" text-xs font-bold text-black hover:underline"
                >
                  CONTACT
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className=" text-xs font-bold text-black hover:underline"
                >
                  KEEP IN TOUCH
                </a>
              </li>
            </ul>
            
          </div>
          <div className="flex mt-4 md:mt-0 md:ml-4" >
              <input
                id="email"
                type="text"
                className="px-4 py-2 text-gray-700 bg-white border text-sm border-gray-300 rounded-l-md focus:outline-none focus:ring focus:ring-blue-300"
                placeholder="Email Address"
              />
              <button className="px-6 py-2 text-white text-sm bg-black rounded-r-md hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300">
                JOIN
              </button>
            </div>
        </footer>
      </div>
      <div className="bg-[#3d3d3d] py-4" >
        <div className="flex flex-col md:flex-row items-center justify-between px-6" >
        <div className="flex space-x-4" >
            <a href="#">
              <FontAwesomeIcon
                icon={faInstagram}
                className="w-4 h-4 text-white transition-transform  duration-300 hover:text-pink-500 hover:scale-150"
              />
            </a>
            <a href="#">
              <FontAwesomeIcon
                icon={faFacebookF}
                className="w-4 h-4 text-white transition-transform  duration-300 hover:text-blue-600 hover:scale-150"
              />
            </a>
            <a href="#">
              <FontAwesomeIcon
                icon={faYoutube}
                className="w-4 h-4 text-white transition-transform  duration-300 hover:text-red-600 hover:scale-150"
              />
            </a>
            <a href="#">
              <FontAwesomeIcon
                icon={faLine}
                className="w-4 h-4 text-white transition-transform  duration-300 hover:text-green-500 hover:scale-150"
              />
            </a>
          </div>
          <h1 className="mt-4 md:mt-0 text-xs text-white text-center md:text-left">
            Copyright ©2024 All Rights Reserved.
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Footer;
