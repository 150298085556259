import React, { useState, useEffect } from "react";

interface Product {
  id: number;
  code: string;
  name: string;
  description: string;
  category_id: number;
  createdAt: string;
  updatedAt: string | null;
  path: string | null;
  price: string;
}

const ShopComponent: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch("https://api.talmidwear.com/products/all");
        const data: Product[] = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const handleProductEnter = (id: number) => {
    setHoveredItem(id);
  };

  const handleProductLeave = () => {
    setHoveredItem(null);
  };

  const formatPrice = (price: string) => {
    const parsedPrice = parseFloat(price);
    if (isNaN(parsedPrice)) return price;
    return parsedPrice.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="grid grid-cols-2 gap-x-6 px-5 lg:px-10 gap-y-10 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8">
      {products.map((product) => (
        <a key={product.id} href={`/detail/${product.id}`} className="group">
          <div className="flex justify-center">
            <div className="aspect-h-1 aspect-w-1 w-full h-full overflow-hidden flex items-center  justify-center xl:aspect-h-8 xl:aspect-w-7">
              <img
                src={
                  hoveredItem === product.id
                    ? product.path
                      ? product.path.replace(
                          "{api_path}",
                          "https://api.talmidwear.com"
                        )
                      : "/404-not-found.png"
                    : product.path
                    ? product.path.replace(
                        "{api_path}",
                        "https://api.talmidwear.com"
                      )
                    : "/404-not-found.png"
                }
                className="w-full md:h-[400px] md:object-contain "
                alt={product.name}
                onMouseEnter={() => handleProductEnter(product.id)}
                onMouseLeave={handleProductLeave}
              />
            </div>
          </div>
          <h3 className="text-xs font-semibold flex justify-center mt-4 text-black text-center">
            {product.name}
          </h3>
          <p className="mt-1 text-xs  flex justify-center text-black text-center">
            ฿{formatPrice(product.price)}
          </p>
          <div className="flex gap-4 justify-center mb-2 opacity-0 group-hover:opacity-100 transform transition-opacity duration-300">
            {product.category_id !== 33
              ? ["S", "M", "L", "XL"].map((size) => (
                  <div key={size} className="text-xs font-semibold ">
                    {size}
                  </div>
                ))
              : ["M", "L", "XL"].map((size) => (
                  <div key={size} className="text-xs font-semibold ">
                    {size}
                  </div>
                ))}
          </div>
        </a>
      ))}
    </div>
  );
};

export default ShopComponent;
