import { Button, Grid, IconButton, Typography, styled } from '@mui/material';
import React from 'react'
import back from '../assets/back.png'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import StartIcon from '@mui/icons-material/Start';

type Props = {
    setValue: React.Dispatch<React.SetStateAction<number>>;

}
const Img = styled("img")(({ theme }) => ({
}));
const Text20px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "18px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "20px"
    },
}));
const Text14px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "10px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "16px"
    },
}));
function Shipping({ setValue }: Props) {
    return (
        <Grid container sx={{rowGap:"24px"}}>
            <Grid sx={{ width: "320px" }}>
                <Grid container sx={{ width: "320px", flexDirection: "row" }}>
                    <Grid width={"80%"}>
                        <Img src={back} width={"100%"} />
                    </Grid>
                    <Grid container width={"19%"} flexDirection={"column"} sx={{ alignItems: "center", justifyContent: "start", rowGap: "12px" }}>
                        <IconButton>
                            <CloseIcon />
                        </IconButton>
                        <Text14px
                        style={{ fontFamily: 'Verdana, sans-serif' }}>
                            <b>
                                L
                            </b>
                        </Text14px>
                        <Grid sx={{ width: "40px", height: "40px", backgroundColor: "#000" }}>

                        </Grid>
                        <Grid container sx={{ flexDirection: "column", gap: "1px" }}>
                            <Grid sx={{ border: "1px solid #000" }}>
                                <IconButton>
                                    <AddIcon />
                                </IconButton>
                            </Grid>
                            <Grid container sx={{ border: "1px solid #000", width: "40px", height: "40px", alignItems: "center", justifyContent: "center" }}>
                                <Text14px
                                style={{ fontFamily: 'Verdana, sans-serif' }}>
                                    <b>
                                        1
                                    </b>
                                </Text14px>
                            </Grid>
                            <Grid sx={{ border: "1px solid #000" }}>
                                <IconButton>
                                    <RemoveIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Text14px
                    style={{ fontFamily: 'Verdana, sans-serif' }}>
                        T-Shirt
                    </Text14px>
                    <Grid container justifyContent={"space-between"}>
                        <Text20px style={{ fontFamily: 'Verdana, sans-serif' }}>Lorem ipsum dolor </Text20px>
                        <Text20px style={{ fontFamily: 'Verdana, sans-serif' }}>590฿</Text20px>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent={"end"}>
                <Button variant="contained" onClick={() => setValue(2)} sx={{ borderRadius: "0" }}>
                    <Grid container sx={{ gap: "24px", alignItems: "center" }}>
                        <Text14px style={{ fontFamily: 'Verdana, sans-serif' }}>Continue</Text14px>  <StartIcon />
                    </Grid>
                </Button>
            </Grid>
        </Grid>
    )
}

export default Shipping