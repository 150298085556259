import {
  Button,
  Grid,
  Rating,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import peple from "../assets/peple.png";
import card from "../assets/card.png";
import T from "../assets/T.png";
import photo1 from "../assets/1.png";
import photo2 from "../assets/2.png";
import photo3 from "../assets/3.png";
import photo4 from "../assets/4.png";
import photo5 from "../assets/5.png";
import avtar from "../assets/avater.png";
import Left from "../assets/left.png";
import Right from "../assets/right.png";
import Slider from "react-slick";
import "flowbite";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, EffectCards } from "swiper/modules";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import SimpleSlider from "../components/SlickComponent";
import ProductComponent from "../components/ProductComponent";
type Props = {};
const Img = styled("img")(({ theme }) => ({}));
const Text40px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "32px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "36px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "40px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "44px",
  },
}));
const Text14px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
  },
}));
const Text20px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px",
  },
}));
const Text30px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "26px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "30px",
  },
}));
function Home({}: Props) {
  const NaviGate = useNavigate();
  const [alignment, setAlignment] = React.useState("menfashion");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const loopcard = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const imgs = [
    {
      img: photo1,
    },
    {
      img: photo2,
    },
    {
      img: photo3,
    },
    {
      img: photo4,
    },
    {
      img: photo5,
    },
    {
      img: photo1,
    },
    {
      img: photo2,
    },
    {
      img: photo3,
    },
    {
      img: photo4,
    },
    {
      img: photo5,
    },
  ];
  return (
    <div className="flex flex-col h-full">
      <SimpleSlider />
      <ProductComponent />
    </div>
  );
}

export default Home;
