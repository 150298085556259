import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  AccountCircle,
  CloudUploadOutlined,
  DeleteOutline,
  EditOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { unescape } from "querystring";

const AddProduct = () => {
  const cookies = new Cookies(null, { path: "/" });
  const navigate = useNavigate();
  const notify = (msg: any, options: any) => toast(msg, { ...options });
  const [show, setShow] = useState(false);

  const [categoriesRef, setCategoriesRef] = useState<any>([]);
  const productImageRef = useRef<any>("");
  const productIdRef = useRef<any>("");
  const productDetailsNameRef = useRef<any>("");
  const productNameRef = useRef<any>("");
  const productPriceRef = useRef<any>("");
  const productStockRef = useRef<any>("");
  const descriptionRef = useRef<any>("");
  const [categoryRef, setCategoryRef] = useState<any>("");

  const [fileImg, setFileImg] = useState<any>(undefined);
  const [fileImg2, setFileImg2] = useState<any>(undefined);
  const [fileImg3, setFileImg3] = useState<any>(undefined);
  const [fileImg4, setFileImg4] = useState<any>(undefined);
  const [fileImg5, setFileImg5] = useState<any>(undefined);
  const [fileImg6, setFileImg6] = useState<any>(undefined);

  const [fileShowImg, setFileShowImg] = useState<any>(null);
  const [fileShowImg2, setFileShowImg2] = useState<any>(null);
  const [fileShowImg3, setFileShowImg3] = useState<any>(null);
  const [fileShowImg4, setFileShowImg4] = useState<any>(null);
  const [fileShowImg5, setFileShowImg5] = useState<any>(null);
  const [fileShowImg6, setFileShowImg6] = useState<any>(null);

  const handleUpload = (event: any) => {
    if (event.target.files[0] === undefined) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileShowImg(reader.result);
    };

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
    setFileImg(event.target.files[0]);

    // Handle the uploaded file here, for example, you can use FileReader to read the file content
  };
  const handleUpload2 = (event: any) => {
    if (event.target.files[0] === undefined) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileShowImg2(reader.result);
    };

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
    setFileImg2(event.target.files[0]);

    // Handle the uploaded file here, for example, you can use FileReader to read the file content
  };
  const handleUpload3 = (event: any) => {
    if (event.target.files[0] === undefined) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileShowImg3(reader.result);
    };

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
    setFileImg3(event.target.files[0]);

    // Handle the uploaded file here, for example, you can use FileReader to read the file content
  };
  const handleUpload4 = (event: any) => {
    if (event.target.files[0] === undefined) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileShowImg4(reader.result);
    };

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
    setFileImg4(event.target.files[0]);

    // Handle the uploaded file here, for example, you can use FileReader to read the file content
  };
  const handleUpload5 = (event: any) => {
    if (event.target.files[0] === undefined) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileShowImg5(reader.result);
    };

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
    setFileImg5(event.target.files[0]);

    // Handle the uploaded file here, for example, you can use FileReader to read the file content
  };
  const handleUpload6 = (event: any) => {
    if (event.target.files[0] === undefined) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileShowImg6(reader.result);
    };

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
    setFileImg6(event.target.files[0]);

    // Handle the uploaded file here, for example, you can use FileReader to read the file content
  };

  const editorRef = useRef<any>(null);
  const [editorLoaded, setEditorLoaded] = useState(false);

  useEffect(() => {
    setEditorLoaded(true);
    handleResize(); // Call handleResize on component mount to set initial size
    getCategories();

    window.addEventListener("resize", handleResize); // Add event listener for window resize
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener on component unmount
      setEditorLoaded(false);
    };
  }, []);

  const handleResize = () => {
    if (editorRef.current) {
      // Get the parent container's width
      const parentWidth = editorRef.current.parentElement.clientWidth;
      // Set the editor's width to be 90% of the parent container's width
      editorRef.current.style.width = `${parentWidth * 0.9}px`;
    }
  };

  const getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/categories/all`)
      .then((response) => {
        const data = response.data;

        setCategoriesRef(data);
      });
  };

  const handleChange = (event: any) => {
    setCategoryRef(event.target.value);
  };

  const addNewProduct = async () => {
    const productId = productIdRef.current.value;
    // const productDetailsName = productDetailsNameRef.current.value;
    const productName = productNameRef.current.value;
    const productPrice = productPriceRef.current.value;
    const productStock = productStockRef.current.value;
    const description = descriptionRef.current.editor
      .getData()
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "");

    console.log(
      productId,
      productName,
      productPrice,
      productStock,
      description
    );
    if (
      productId != null &&
      productId !== "" &&
      productId !== undefined &&
      productName != null &&
      productName !== "" &&
      productName !== undefined &&
      productPrice != null &&
      productPrice !== "" &&
      productPrice !== undefined &&
      productStock != null &&
      productStock !== "" &&
      productStock !== undefined &&
      categoryRef != null &&
      categoryRef !== "" &&
      categoryRef !== undefined
    ) {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/products/add/`,
          {
            code: productId,
            name: productName,
            description: description,
            category_id: categoryRef,
            price: productPrice,
            stock: productStock,
          },
          {
            headers: {
              token: cookies.get("token"),
            },
          }
        )
        .then(async (response) => {
          if (response.data.message === "OK") {
            Swal.fire({
              title: "เพิ่มสินค้าเรียบร้อยแล้ว",
              icon: "success",
            });
            if (fileImg !== undefined) {
              const formData = new FormData();
              const product_id = response.data.id;
              formData.append("file", fileImg);

              await axios
                .post(
                  `${process.env.REACT_APP_API_URL}/upload/productImg`,
                  formData,
                  {
                    headers: {
                      token: cookies.get("token"),
                    },
                  }
                )
                .then(async (response) => {
                  console.log(response);

                  if (response.data.message == "OK") {
                    await axios.post(
                      `${process.env.REACT_APP_API_URL}/products/insertpathimg`,
                      {
                        product_id: product_id,
                        path_img: response.data.path,
                      },
                      {
                        headers: {
                          token: cookies.get("token"),
                        },
                      }
                    );
                  }
                });
            }
            if (fileImg2 !== undefined) {
              const formData = new FormData();
              const product_id = response.data.id;
              formData.append("file", fileImg2);

              await axios
                .post(
                  `${process.env.REACT_APP_API_URL}/upload/productImg`,
                  formData,
                  {
                    headers: {
                      token: cookies.get("token"),
                    },
                  }
                )
                .then(async (response) => {
                  console.log(response);

                  if (response.data.message == "OK") {
                    await axios.post(
                      `${process.env.REACT_APP_API_URL}/products/insertpathimg2`,
                      {
                        product_id: product_id,
                        path_img: response.data.path,
                      },
                      {
                        headers: {
                          token: cookies.get("token"),
                        },
                      }
                    );
                  }
                });
            }
            if (fileImg3 !== undefined) {
              const formData = new FormData();
              const product_id = response.data.id;
              formData.append("file", fileImg3);

              await axios
                .post(
                  `${process.env.REACT_APP_API_URL}/upload/productImg`,
                  formData,
                  {
                    headers: {
                      token: cookies.get("token"),
                    },
                  }
                )
                .then(async (response) => {
                  console.log(response);

                  if (response.data.message == "OK") {
                    await axios.post(
                      `${process.env.REACT_APP_API_URL}/products/insertpathimg3`,
                      {
                        product_id: product_id,
                        path_img: response.data.path,
                      },
                      {
                        headers: {
                          token: cookies.get("token"),
                        },
                      }
                    );
                  }
                });
            }
            if (fileImg4 !== undefined) {
              const formData = new FormData();
              const product_id = response.data.id;
              formData.append("file", fileImg4);

              await axios
                .post(
                  `${process.env.REACT_APP_API_URL}/upload/productImg`,
                  formData,
                  {
                    headers: {
                      token: cookies.get("token"),
                    },
                  }
                )
                .then(async (response) => {
                  console.log(response);

                  if (response.data.message == "OK") {
                    await axios.post(
                      `${process.env.REACT_APP_API_URL}/products/insertpathimg4`,
                      {
                        product_id: product_id,
                        path_img: response.data.path,
                      },
                      {
                        headers: {
                          token: cookies.get("token"),
                        },
                      }
                    );
                  }
                });
            }
            if (fileImg5 !== undefined) {
              const formData = new FormData();
              const product_id = response.data.id;
              formData.append("file", fileImg5);

              await axios
                .post(
                  `${process.env.REACT_APP_API_URL}/upload/productImg`,
                  formData,
                  {
                    headers: {
                      token: cookies.get("token"),
                    },
                  }
                )
                .then(async (response) => {
                  console.log(response);

                  if (response.data.message == "OK") {
                    await axios.post(
                      `${process.env.REACT_APP_API_URL}/products/insertpathimg5`,
                      {
                        product_id: product_id,
                        path_img: response.data.path,
                      },
                      {
                        headers: {
                          token: cookies.get("token"),
                        },
                      }
                    );
                  }
                });
            }
            if (fileImg6 !== undefined) {
              const formData = new FormData();
              const product_id = response.data.id;
              formData.append("file", fileImg6);

              await axios
                .post(
                  `${process.env.REACT_APP_API_URL}/upload/productImg`,
                  formData,
                  {
                    headers: {
                      token: cookies.get("token"),
                    },
                  }
                )
                .then(async (response) => {
                  console.log(response);

                  if (response.data.message == "OK") {
                    await axios.post(
                      `${process.env.REACT_APP_API_URL}/products/insertpathimg6`,
                      {
                        product_id: product_id,
                        path_img: response.data.path,
                      },
                      {
                        headers: {
                          token: cookies.get("token"),
                        },
                      }
                    );
                  }
                });
            }
            const timer = setTimeout(() => {
              setShow(true);
              window.location.reload();
            }, 10000);

            return () => clearTimeout(timer);
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: `เพิ่มสินค้าไม่สำเร็จ (${response.data.message})`,
              icon: "error",
            });
          }
        });
    } else {
      notify("Please input your product data", {
        duration: 5000,
        style: {
          color: "white",
          backgroundColor: "red",
        },
        icon: "❌",
        position: "bottom-center",
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      });
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center">
        <input
          accept="image/*"
          id="upload-photo1"
          type="file"
          style={{ display: "none" }}
          onChange={handleUpload}
        />
        <label htmlFor="upload-photo1">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadOutlined />}
          >
            Upload Photo
          </Button>
        </label>

        <Typography variant="body2" color="textSecondary">
          &nbsp;&nbsp;Maximum file size: 5MB
        </Typography>
        {fileShowImg && (
          <img
            src={fileShowImg}
            alt=""
            className="mx-12 object-contain w-48 h-48"
          />
        )}
      </Stack>
      <Stack direction="row" alignItems="center">
        <input
          accept="image/*"
          id="upload-photo2"
          type="file"
          style={{ display: "none" }}
          onChange={handleUpload2}
        />
        <label htmlFor="upload-photo2">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadOutlined />}
          >
            Upload Photo
          </Button>
        </label>

        <Typography variant="body2" color="textSecondary">
          &nbsp;&nbsp;Maximum file size: 5MB
        </Typography>
        {fileShowImg2 && (
          <img
            src={fileShowImg2}
            alt=""
            className="mx-12 object-contain w-48 h-48"
          />
        )}
      </Stack>
      <Stack direction="row" alignItems="center">
        <input
          accept="image/*"
          id="upload-photo3"
          type="file"
          style={{ display: "none" }}
          onChange={handleUpload3}
        />
        <label htmlFor="upload-photo3">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadOutlined />}
          >
            Upload Photo
          </Button>
        </label>

        <Typography variant="body2" color="textSecondary">
          &nbsp;&nbsp;Maximum file size: 5MB
        </Typography>
        {fileShowImg3 && (
          <img
            src={fileShowImg3}
            alt=""
            className="mx-12 object-contain w-48 h-48"
          />
        )}
      </Stack>
      <Stack direction="row" alignItems="center">
        <input
          accept="image/*"
          id="upload-photo4"
          type="file"
          style={{ display: "none" }}
          onChange={handleUpload4}
        />
        <label htmlFor="upload-photo4">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadOutlined />}
          >
            Upload Photo
          </Button>
        </label>

        <Typography variant="body2" color="textSecondary">
          &nbsp;&nbsp;Maximum file size: 5MB
        </Typography>
        {fileShowImg4 && (
          <img
            src={fileShowImg4}
            alt=""
            className="mx-12 object-contain w-48 h-48"
          />
        )}
      </Stack>
      <Stack direction="row" alignItems="center">
        <input
          accept="image/*"
          id="upload-photo5"
          type="file"
          style={{ display: "none" }}
          onChange={handleUpload5}
        />
        <label htmlFor="upload-photo5">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadOutlined />}
          >
            Upload Photo
          </Button>
        </label>

        <Typography variant="body2" color="textSecondary">
          &nbsp;&nbsp;Maximum file size: 5MB
        </Typography>
        {fileShowImg5 && (
          <img
            src={fileShowImg5}
            alt=""
            className="mx-12 object-contain w-48 h-48"
          />
        )}
      </Stack>
      <Stack direction="row" alignItems="center">
        <input
          accept="image/*"
          id="upload-photo6"
          type="file"
          style={{ display: "none" }}
          onChange={handleUpload6}
        />
        <label htmlFor="upload-photo6">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadOutlined />}
          >
            Upload Photo
          </Button>
        </label>

        <Typography variant="body2" color="textSecondary">
          &nbsp;&nbsp;Maximum file size: 5MB
        </Typography>
        {fileShowImg6 && (
          <img
            src={fileShowImg6}
            alt=""
            className="mx-12 object-contain w-48 h-48"
          />
        )}
      </Stack>
      <br></br>

      <Grid container spacing={2}>
        <Grid item sm={6} md={6}>
          <FormControl fullWidth>
            <FormLabel>เลือกหมวดหมู่</FormLabel>
            <Select
              id="standard-bare"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
              onChange={handleChange}
            >
              {categoriesRef.map((item: any) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} md={6}>
          <FormControl fullWidth>
            <FormLabel>รหัสสินค้า</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="รหัสสินค้า"
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
              inputRef={productIdRef}
            />
          </FormControl>
        </Grid>

        <Grid item sm={12} md={12}>
          <FormControl fullWidth>
            <FormLabel>ชื่อสินค้า</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="ชื่อสินค้า"
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
              inputRef={productNameRef}
            />
          </FormControl>
        </Grid>
        {/* <Grid item sm={6} md={6}>
          <FormControl fullWidth>
            <FormLabel>ยี่ห้อสินค้า</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="ยี่ห้อสินค้า"
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
              inputRef={productNameRef}
            />
          </FormControl>
        </Grid> */}
        <Grid item sm={6} md={6}>
          <FormControl fullWidth>
            <FormLabel>ราคา</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="ราคา"
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
              inputRef={productPriceRef}
            />
          </FormControl>
        </Grid>
        <Grid item sm={6} md={6}>
          <FormControl fullWidth>
            <FormLabel>จำนวน Stock</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="ราคา"
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
              inputRef={productStockRef}
            />
          </FormControl>
        </Grid>

        <Grid item sm={12} md={12}>
          <div className="App inline w-full" ref={editorRef}>
            <FormLabel>คำอธิบาย</FormLabel>
            {editorLoaded && (
              <CKEditor
                editor={ClassicEditor}
                data=""
                ref={descriptionRef}
                onReady={(editor: any) => {
                  editor.ui.view.editable.element.style.height = "250px";
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event) => {
                  console.log(event);
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            )}
          </div>
        </Grid>

        <Grid item sm={12} md={12}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Button
                fullWidth
                variant={"contained"}
                onClick={() => {
                  addNewProduct();
                }}
              >
                เพิ่มสินค้า
              </Button>
            </Grid>
            {/* <Grid item md={6}>
                <Button fullWidth variant={'contained'} color={'error'}><DeleteOutline fontSize={'small'}> </DeleteOutline> ลบสินค้า</Button>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddProduct;
