import {
  Box,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect } from "react";
import ProductComponent from "../components/ProductComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLine,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "aos/dist/aos.css";
import AOS from "aos";

const Text20px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px",
  },
}));
function Contact() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className=" container mx-auto justify-center flex mt-10">
      {/* <div className="w-full mt-6 " data-aos="fade-up" data-aos-duration="1000">
        <img
          className="w-full h-full"
          src="/image6.png"
          alt=""
        />
      </div>
      <div className="lg:justify-end justify-center flex px-16 mt-7" data-aos="fade-left" data-aos-duration="1000">
        <h1 className="font-bold lg:text-[30px] text-[20px] ">CONTACT</h1>
      </div>
      <div className="flex mt-7 lg:px-16 gap-4 lg:gap-10 lg:justify-end justify-center w-full h-full" data-aos="fade-left" data-aos-duration="1000">
        <div>
          <a href="#">
            <FontAwesomeIcon icon={faInstagram} className="w-8 h-8 rounded-full p-3 bg-black text-white" />
          </a>
        </div>
        <div>
          <a href="#">
            <FontAwesomeIcon icon={faFacebookF} className="w-8 h-8 rounded-full p-3 bg-black text-white" />
            </a>
        </div>
        <div>
          <a href="#">
          <FontAwesomeIcon icon={faYoutube} className="w-8 h-8 rounded-full p-3 bg-black text-white" />

          </a>
        </div>
        <div>
          <a href="#">
          <FontAwesomeIcon icon={faLine} className="w-8 h-8 rounded-full p-3 bg-black text-white" />

          </a>
        </div>
      </div>
      <div className="mt-6">
        <ProductComponent />
      </div> */}

      <div
        className=" max-w-3xl justify-center  p-4 px-10 py-10"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className="text-left">
          <h2 className="text-base font-semibold mb-2">CONTACT</h2>
          <div className="py-6">
            <p className="mb-1 text-sm">GENERAL INQUIRIES</p>
            <a
              href="mailto:talmid.official@gmail.com"
              target="__blank"
              className="mb-4 text-sm uppercase"
            >
              talmid.official@gmail.com
            </a>
          </div>
          <div className="py-7">
            <p className="mb-1 text-sm">RETURNS</p>
            <a href="/" className="mb-4 text-sm uppercase">
              talmidwear.com
            </a>
          </div>

          <p className=" mb-4 text-sm">SEND US A MESSAGE</p>
          <p className="text-xs mb-6">
            TO ENSURE YOU RECEIVE EMAILS, PLEASE CHECK YOUR SPAM FOLDER FOR
            MESSAGES. MAKE SURE THAT YOUR EMAIL SETTINGS ARE UP TO DATE BY
            ADDING OUR{" "}
            <a
              href="mailto:talmid.official@gmail.com"
              target="__blank"
              className=" text-sm uppercase"
            >
              talmid.official@gmail.com
            </a>{" "}
            EMAIL ADDRESS TO YOUR SAFE SENDERS LIST.
          </p>
        </div>

        <form className="space-y-4">
          <div>
            <input
              type="text"
              placeholder="NAME"
              className="w-full text-xs p-2 py-3 border border-gray-300 rounded text-gray-400 hover:border-gray-400 transform duration-300"
            />
          </div>
          <div>
            <select className="w-full p-2 border text-xs text-gray-500 border-gray-300 py-3 rounded hover:border-gray-400 transform duration-300">
              <option>GENERAL INQUIRY</option>
              <option>ORDER STATUS</option>
              <option>RETURNS & EXCHANGES</option>
              <option>INTERNATIONAL RETURNS</option>
              <option>DAMAGES</option>
              <option>MY ACCOUNT</option>
              <option>CANCELATION REQUEST</option>
              <option>OTHER</option>
            </select>
          </div>
          <div>
            <input
              type="email"
              placeholder="EMAIL"
              className="w-full text-xs p-2 py-3 border border-gray-300 rounded text-gray-400 hover:border-gray-400 transform duration-300"
            />
          </div>
          <div>
            <textarea
              placeholder="MESSAGE"
              rows={6}
              className="w-full text-xs p-2 border border-gray-300 rounded text-gray-400 hover:border-gray-400 transform duration-300"
            ></textarea>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="px-4 w-full py-3 text-xs bg-white text-black rounded hover:bg-black hover:text-white transform duration-300 border-black border"
            >
              SEND
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
