import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  AccountCircle,
  CloudUploadOutlined,
  DeleteOutline,
  Description,
  Details,
  EditOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { unescape } from "querystring";

const EditUser = ({ param_id }: { param_id: string }) => {
  const cookies = new Cookies(null, { path: "/" });
  const navigate = useNavigate();
  const notify = (msg: any, options: any) => toast(msg, { ...options });

  const [userRef, setUserRef] = useState<any>([]);
  const [userIdRef, setUserIdRef] = useState<any>("");
  const [userFNameRef, setUserFNameRef] = useState<any>("");
  const [userLNameRef, setUserLNameRef] = useState<any>("");
  const [userPhoneRef, setUserPhoneRef] = useState<any>("");
  const [userEmailRef, setUserEmailRef] = useState<any>("");
  const [userRoleRef, setUserRoleRef] = useState<any>("");
  const [cold, setCold] = useState<boolean>(false);

  useEffect(() => {
    getProducts();
  }, []);

  //   const getCategories = () => {
  //     axios
  //       .get(`${process.env.REACT_APP_API_URL}/categories/all`)
  //       .then((response) => {
  //         const data = response.data;

  //         setCategoriesRef(data);
  //       });
  //   };

  const getProducts = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/members/getAllProfile/`, {
        headers: {
          token: cookies.get("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        setUserRef(data[param_id]);
        setUserIdRef(data[param_id]["id"]);
        setUserEmailRef(data[param_id]["email"]);
        setUserFNameRef(data[param_id]["firstname"]);
        setUserLNameRef(data[param_id]["lastname"]);
        setUserPhoneRef(data[param_id]["phone"]);
        setUserRoleRef(data[param_id]["isAdmin"]);
      });
  };

  const handleChange = (event: any) => {
    setUserRoleRef(event.target.value);
  };

  const editProduct = async () => {
    // console.log(userRef);

    if (
      userIdRef != null &&
      userIdRef !== "" &&
      userIdRef !== undefined &&
      userFNameRef != null &&
      userFNameRef !== "" &&
      userFNameRef !== undefined &&
      userLNameRef != null &&
      userLNameRef !== "" &&
      userLNameRef !== undefined &&
      userPhoneRef != null &&
      userPhoneRef !== "" &&
      userPhoneRef !== undefined &&
      userEmailRef != null &&
      userEmailRef !== "" &&
      userEmailRef !== undefined &&
      userRoleRef != null &&
      userRoleRef !== "" &&
      userRoleRef !== undefined
    ) {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/members/changeProfileByAdmin/`,
          {
            username: userEmailRef,
            firstname: userFNameRef,
            lastname: userLNameRef,
            phoneNumber: userPhoneRef,
            email: userEmailRef,
            isAdmin: userRoleRef,
          },
          {
            headers: {
              token: cookies.get("token"),
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          if (response.data.message === "success") {
            Swal.fire({
              title: "แก้ไขผู้ใช้เรียบร้อยแล้ว",
              icon: "success",
            });
            const timer = setTimeout(() => {
              window.location.reload();
            }, 3000);

            return () => clearTimeout(timer);
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: `แก้ไขผู้ใช้ไม่สำเร็จ (${response.data.message})`,
              icon: "error",
            });
          }
        });
    } else {
      notify("Please input your product data", {
        duration: 5000,
        style: {
          color: "white",
          backgroundColor: "red",
        },
        icon: "❌",
        position: "bottom-center",
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      });
    }
  };

  const deleteProduct = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/member/deleteProfileByAdmin/`,
        {
          email: userEmailRef,
        },
        {
          headers: {
            token: cookies.get("token"),
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "success") {
          Swal.fire({
            title: "ลบผู้ใช้เรียบร้อยแล้ว",
            icon: "success",
          });
          const timer = setTimeout(() => {
            window.location.reload();
          }, 3000);

          return () => clearTimeout(timer);
        } else {
          Swal.fire({
            title: "เกิดข้อผิดพลาด",
            text: `ลบผู้ใช้ไม่สำเร็จ (${response.data.message})`,
            icon: "error",
          });
        }
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={6} md={6}>
          <FormControl fullWidth>
            <FormLabel>เลือกตำแหน่งผู้ใช้</FormLabel>
            <Select
              id="standard-bare"
              variant="outlined"
              value={userRoleRef}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={1}>Admin</MenuItem>
              <MenuItem value={0}>User</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} md={6}>
          <FormControl fullWidth>
            <FormLabel>เบอร์โทรศัพท์</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="เบอร์โทรศัพท์..."
              value={userPhoneRef}
              onChange={(e) => setUserPhoneRef(e.target.value)}
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item sm={12} md={12}>
          <FormControl fullWidth>
            <FormLabel>อีเมล</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="อีเมล..."
              value={userEmailRef}
              onChange={(e) => setUserEmailRef(e.target.value)}
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item sm={6} md={6}>
          <FormControl fullWidth>
            <FormLabel>ชื่อ</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="ชื่อ..."
              value={userFNameRef}
              onChange={(e) => setUserFNameRef(e.target.value)}
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item sm={6} md={6}>
          <FormControl fullWidth>
            <FormLabel>นามสกุล</FormLabel>
            <TextField
              id="standard-bare"
              variant="outlined"
              placeholder="นามสกุล..."
              value={userLNameRef}
              onChange={(e) => setUserLNameRef(e.target.value)}
              InputProps={{
                sx: {
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  py: "8px",
                  fontSize: "14px",
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item sm={12} md={12}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Button
                fullWidth
                variant={"contained"}
                onClick={() => {
                  editProduct();
                }}
              >
                <EditOutlined fontSize={"small"}></EditOutlined>แก้ไขผู้ใช้
              </Button>
            </Grid>
            <Grid item md={6}>
              <Button
                fullWidth
                variant={"contained"}
                color={"error"}
                onClick={() => {
                  deleteProduct();
                }}
              >
                <DeleteOutline fontSize={"small"}> </DeleteOutline> ลบผู้ใช้
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EditUser;
