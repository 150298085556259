import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'aos/dist/aos.css';
import AOS from 'aos';
import { useEffect } from 'react';

export default function SimpleSlider() {
  const [liked, setLiked] = useState(false); // State to track liked status

  const sliderRef = React.useRef(null) as React.MutableRefObject<any>;

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);

  const handlePrevSlideAndLikeToggle = () => {
    handleLikeToggle();
    prevSlide();
  };

  const handleLikeToggle = () => {
    setLiked(!liked); // Toggle liked state
  };

  const images = [
    "/B1.jpg",
    "/B2.jpg",
    "/B3.jpg",
    "/B4.jpg",
  ];
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className="flex flex-col w-full justify-center relative " data-aos="fade-up" data-aos-duration="1000">
      <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => (
          <div key={index} className="w-full h-full relative">
            <img
              className="object-cover w-full h-[250px] lg:h-[643px]"
              src={image}
              alt={`Slide ${index + 1}`}
            />
          </div>
        ))}
      </Slider>
      <a href="/shop">
        <button
          style={{ fontFamily: "Verdana, sans-serif" }}
          type="button"
          className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white bg-black hover:bg-[#A28342] focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium text-sm px-5 py-2.5 me-2 mb-2"
        >
          SHOP NOW
        </button>
      </a>
      {/* <div className=" flex justify-center items-center absolute bottom-72 w-full">
        <button
          onClick={prevSlide}
          type="button"
          className="absolute bottom-4 left-9 transform -translate-x-1/2 text-black hover:text-white bg-white hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2"
        >
          <svg
            className="w-4 h-4 rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Icon description</span>
        </button>
        <button
          onClick={nextSlide}
          type="button"
          className="absolute bottom-4 right-0 transform -translate-x-1/2 text-black hover:text-white bg-white hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2"
        >
          <svg
            className="w-4 h-4"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Icon description</span>
        </button>
      </div> */}
    </div>
  );
}
