import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import paypal from "../assets/paypal.png";
import promptpay from "../assets/PromptPay.png";
import Alway from "../components/Alway";
import ProductCard from "../components/ProductCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "universal-cookie";

function Payment() {
  const Text20px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
    },
  }));
  const Text30px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "28px",
    },
  }));

  const [count, setCount] = useState(0);

  const incrementCount = () => {
    setCount(count + 1); // เพิ่ม count ทีละ 1 หลังจากที่กดปุ่ม
  };
  const decrementCount = () => {
    setCount(count - 1); // ลบ count ทีละ 1 หลังจากที่กดปุ่ม
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const price = searchParams.get("price");
  const oid = searchParams.get("oid");
  const [FileImg, setFileImg] = useState(undefined);

  const cookies = new Cookies(null, { path: "/" });
  const navigate = useNavigate();

  const handleUpload = (event: any) => {
    if (event.target.files[0] === undefined) {
      return;
    }

    setFileImg(event.target.files[0]);
  };

  const payNow = async () => {
    if (FileImg !== undefined) {
      const formData = new FormData();
      formData.append("file", FileImg);

      await axios
        .post(`${process.env.REACT_APP_API_URL}/upload/productImg`, formData, {
          headers: {
            token: cookies.get("token"),
          },
        })
        .then(async (response) => {
          if (response.data.message == "OK") {
            await axios.post(
              `${process.env.REACT_APP_API_URL}/orders/updatePay`,
              {
                id: oid,
                path_img: response.data.path,
              },
              {
                headers: {
                  token: cookies.get("token"),
                },
              }
            );
            Swal.fire({
              title: "การสั่งซื้อสำเร็จ",
              icon: "success",
            }).then(() => {
              return navigate("/");
            });
          }
        });
    } else {
      Swal.fire({
        title: "ไม่พบรูป",
        text: `กรุณาแนบสลิป`,
        icon: "error",
      });
    }
  };

  return (
    <div className="inline w-full justify-center">
      <div className="w-full px-[2%] py-[1%] flex justify-between border-t-2">
        <a href="/checkout" className=" text-sm font-semibold">
          <button>
            <FontAwesomeIcon icon={faAngleLeft} className="mx-2 " />
            BACK TO CHECKOUT
          </button>
        </a>
      </div>
      <div className="w-full flex flex-col px-[5%] my-12">
        <div className="flex flex-col">
          <div>
            <h1 className=" text-sm font-semibold mb-5">CHECKOUT</h1>
            <span className="text-sm font-semibold  text-gray-500 pr-3">
              INFORMATION
            </span>
            <span className="text-sm font-semibold  text-gray-500  px-3">
              SHOPPING
            </span>
            <span className="text-sm font-semibold  text-black px-3">
              PAYMENT
            </span>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-5 mt-10 w-full">
          <div className="flex flex-col w-full">
            <div className="bg-white border flex flex-col items-center w-full shadow-md">
              <h1 className="text-lg mb-5 bg-[#383D41] w-full p-2 flex text-center items-center justify-center text-white font-bold ">
                รายละเอียดบัญชีฝาก
              </h1>
              <img
                className="h-[80px] w-[80px] mt-5"
                src="/Group 2377.png"
                alt=""
              />
              <p className="pb-5">ธนาคาร Lorem</p>
              <p className="pb-5 text-lg font-medium">5724342444</p>
              <a
                href="#"
                className="text-white font-bold p-5 inline-flex items-center justify-center  w-[307px] h-[47.95px] px-3 py-2 text-sm text-center rounded-md  bg-gray-400 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                คัดลอกบัญชี
              </a>
              <p className="p-5 text-lg font-medium">Lorem ipsum 111</p>
            </div>

            <div className="mt-6">
              <form action="/complete">
                <div className="bg-white border flex flex-col p-[5%] w-full shadow-md justify-center">
                  <p className="pb-5">ระบุจำนวนเงินที่ต้องการโอน</p>
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-3xl flex justify-center font-bold">
                    {/* <input
                      type="text"
                      id="simple-search"
                      className="w-full text-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 ps-10 pe-10 focus:border-blue-500 block p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="ระบุจำนวนเงินที่ต้องการโอน"
                      required
                    /> */}
                    {price} ฿
                  </div>
                  <p className="pb-5">เลือกสลิปโอนเงิน</p>
                  <input
                    accept="image/*"
                    id="upload-photo"
                    type="file"
                    onChange={handleUpload}
                  />
                  <p className="text-sm text-gray-500">
                    กรุณาแนบสลิปทุกครั้ง !
                  </p>
                  <div className="flex mt-5 w-full">
                    <button
                      type="button"
                      onClick={() => payNow()}
                      className="w-1/2 text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                    >
                      ตกลง
                    </button>
                    <a href="/" className="w-1/2">
                      <button
                        type="button"
                        className="w-full focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                      >
                        ยกเลิก
                      </button>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* <div className='bg-white border flex flex-col items-center shadow-md ml-10 h-auto w-[500px]'>
                    <h1 className='mb-5 bg-[#383D41] w-full p-2 flex text-center items-center justify-center text-white font-bold '>รายละเอียดบัญชีฝาก</h1>
                    <img
                        className="h-[50px] w-[50px]"
                        src="/Group 2377.png" alt="" />
                    <p className='p-5 '>ธนาคาร Lorem</p>
                    <a
                        href="#"
                        className="p-5 inline-flex items-center justify-center  w-[307px] h-[47.95px] px-3 py-2 text-sm font-medium text-center text-black bg-[#D9D9D9]  hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        Continue
                    </a>
                    <p className='p-5 '>Lorem ipsum 111</p>
                </div>
          <ProductCard props={""} /> */}
        </div>
      </div>
    </div>
  );
}

export default Payment;
