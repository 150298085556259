import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import StartIcon from "@mui/icons-material/Start";
import ProductCard from "../components/ProductCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
type Props = {
  setValue?: React.Dispatch<React.SetStateAction<number>>;
};
const Text20px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "30px",
  },
}));
const Text14px = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "14px",
  },
}));
function Information() {
  const [country, setCountry] = React.useState("country");
  const navigate = useNavigate();

  const handleChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value);
  };
  const cookies = new Cookies(null, { path: "/" });

  const [Email, setEmail] = React.useState("");
  const [Phone, setPhone] = React.useState("");
  const [Fname, setFname] = React.useState("");
  const [Lname, setLname] = React.useState("");
  const [State, setState] = React.useState("");
  const [Address, setAddress] = React.useState("");
  const [City, setCity] = React.useState("");
  const [Postal, setPostal] = React.useState("");
  const [Product, setProduct] = React.useState([]);
  const [PID, setPID] = React.useState("");
  const [PCode, setCode] = React.useState("");
  const [PName, setPName] = React.useState("");
  const [PPrice, setPPrice] = React.useState("");
  const [img, setImg] = React.useState("");
  const [Cold, setCold] = React.useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const size = searchParams.get("size");

  const fetchProduct = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/products/id/${id}`)
      .then((response) => {
        const data = response.data;
        setPID(data.product[0].id);
        setPName(data.product[0].name);
        setPPrice(data.priceOfProduct[0].price);
        setCode(data.product[0].code);
        const dataImg = data.imageOfProduct[0].path;
        setImg(dataImg.replace("{api_path}", process.env.REACT_APP_API_URL));
        setProduct(data);
      });
  };
  if (Cold === false) {
    fetchProduct();
    setCold(true);
  }
  const orderNow = async () => {
    if (
      Email != null &&
      Email !== "" &&
      Email !== undefined &&
      Phone != null &&
      Phone !== "" &&
      Phone !== undefined &&
      Fname != null &&
      Fname !== "" &&
      Fname !== undefined &&
      Lname != null &&
      Lname !== "" &&
      Lname !== undefined &&
      State != null &&
      State !== "" &&
      State !== undefined &&
      Address != null &&
      Address !== "" &&
      Address !== undefined &&
      City != null &&
      City !== "" &&
      City !== undefined &&
      Postal != null &&
      Postal !== "" &&
      Postal !== undefined &&
      country != null &&
      country !== "country" &&
      country !== undefined
    ) {
      let address =
        Fname +
        " " +
        Lname +
        ", " +
        Address +
        " " +
        City +
        " " +
        State +
        " " +
        Postal +
        " " +
        country +
        ", " +
        Email +
        " " +
        Phone;
      const dataNow = "CODE :" + PCode + " NAME: " + PName + " Size: " + size;
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/orders/add/`,
          {
            address: address,
            data: dataNow,
          },
          {
            headers: {
              token: cookies.get("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.message === "OK") {
            return navigate(`/checkout?pid=${id}&oid=${response.data.id}`);
          }
        });
    } else {
      Swal.fire({
        title: "ข้อมูลไม่ครบถ้วน",
        text: `กรุณากรอกข้อมูลให้ครบถ้วน`,
        icon: "error",
      });
    }
  };

  return (
    <div className="inline justify-center">
      <div className="w-full px-[2%] py-[1%] flex justify-between border-t-2">
        <a href="/detail" className="text-xs font-semibold">
          <button>
            <FontAwesomeIcon icon={faAngleLeft} className="mx-2 " />
            BACK TO DETAIL
          </button>
        </a>
      </div>
      <div className="w-full px-[5%] my-16 gap-0 md:gap-[5%] flex md:flex-row flex-col">
        <div className="w-full md:w-1/2">
          <div className="flex flex-col w-full">
            <div>
              <h1 className=" text-sm font-semibold mb-5">CHECKOUT</h1>
              <span className=" text-sm text-black  pr-3">INFORMATION</span>
              <span className=" text-sm text-gray-500 px-3">SHOPPING</span>
              <span className=" text-sm text-gray-500 px-3">PAYMENT</span>
            </div>
          </div>
          <div>
            <Grid className="w-96 mt-9" container sx={{ gap: "24px" }}>
              <Grid container sx={{ rowGap: "12px" }}>
                <Text14px>Contact info</Text14px>
                <TextField
                  className="w-20"
                  fullWidth
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  variant="outlined"
                  required
                />
                <TextField
                  fullWidth
                  placeholder="Phone"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid container sx={{ rowGap: "12px" }}>
                <Text14px>Shipping address</Text14px>
                <Grid
                  container
                  sx={{ flexDirection: "row", justifyContent: "space-between" }}
                >
                  <TextField
                    sx={{ width: "49%" }}
                    placeholder="First Name"
                    onChange={(e) => {
                      setFname(e.target.value);
                    }}
                    variant="outlined"
                    required
                  />
                  <TextField
                    sx={{ width: "49%" }}
                    placeholder="Last Name"
                    onChange={(e) => {
                      setLname(e.target.value);
                    }}
                    variant="outlined"
                    required
                  />
                </Grid>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={country}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value={"country"} disabled>
                      Country
                    </MenuItem>
                    <MenuItem value={"Thai"}>Thai</MenuItem>
                    <MenuItem value={"USA"}>USA</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  placeholder="State / Region"
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                  variant="outlined"
                  required
                />
                <TextField
                  fullWidth
                  required
                  placeholder="Address"
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  variant="outlined"
                />
                <Grid
                  container
                  sx={{ flexDirection: "row", justifyContent: "space-between" }}
                >
                  <TextField
                    sx={{ width: "49%" }}
                    placeholder="City"
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                    variant="outlined"
                    required
                  />
                  <TextField
                    sx={{ width: "49%" }}
                    placeholder="Postal Code"
                    onChange={(e) => {
                      setPostal(e.target.value);
                    }}
                    variant="outlined"
                    required
                  />
                </Grid>
              </Grid>
              <Button
                type="button"
                onClick={() => orderNow()}
                className="inline-flex justify-center font-semibold hover:text-white items-center w-full h-[47.95px] px-3 py-2 text-sm text-center text-white bg-black  hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                PAY NOW
              </Button>
            </Grid>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <ProductCard
            props={img}
            name={PName}
            size={size || null}
            count={0}
            price={PPrice}
          />
        </div>
      </div>
    </div>
  );
}

export default Information;
