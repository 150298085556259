import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/scrollbar";
import { Scrollbar, Autoplay } from "swiper/modules";
import { Typography, styled } from "@mui/material";

interface Product {
  id: number;
  code: string;
  name: string;
  description: string;
  category_id: number;
  createdAt: string;
  updatedAt: string | null;
  path: string | null;
  price: string;
}

const ProductComponent: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  const Text20px = styled(Typography)(({ theme }) => ({
    fontWeight: "600",
    [theme.breakpoints.up("xs")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "15px",
    },
  }));

  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     try {
  //       const response = await fetch('https://api.talmidwear.com/products/all');
  //       const data: Product[] = await response.json();
  //       const repeatedProducts = [];
  //       for (let i = 0; i < 5; i++) {
  //         repeatedProducts.push(...data);
  //       }
  //       setProducts(repeatedProducts);
  //     } catch (error) {
  //       console.error('Error fetching products:', error);
  //     }
  //   };

  //   fetchProducts();
  // }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch("https://api.talmidwear.com/products/all");
        const data: Product[] = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const handleProductEnter = (id: number) => {
    setHoveredItem(id);
  };

  const handleProductLeave = () => {
    setHoveredItem(null);
  };

  const formatPrice = (price: string) => {
    const parsedPrice = parseFloat(price);
    if (isNaN(parsedPrice)) return price;
    return parsedPrice.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className=" h-full w-full gap-5 items-center py-10">
      <div data-aos="fade-up" data-aos-duration="1000">
        <div className="mt-11 flex justify-center pb-2">
          <Text20px className="font-bold">New Arrivals</Text20px>
        </div>
        <div className="flex justify-center pb-10">
          <div className="w-[55px] md:w-[80px] h-[3px] bg-black"></div>
        </div>
      </div>
      <div
        className="px-5 lg:px-10"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <Swiper
          spaceBetween={30}
          slidesPerView={4}
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          modules={[Autoplay]}
          breakpoints={{
            1024: {
              slidesPerView: 3,
            },
            600: {
              slidesPerView: 2,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            200: {
              slidesPerView: 1,
            },
          }}
        >
          {products.map((product, index) => (
            <SwiperSlide key={index} className="group px-2">
              <a href={`/detail/${product.id}`}>
                <div className="flex justify-center">
                  <div className="w-full h-full overflow-hidden flex items-center justify-center">
                    <img
                      src={
                        product.path
                          ? product.path.replace(
                              "{api_path}",
                              "https://api.talmidwear.com"
                            )
                          : "/404-not-found.png"
                      }
                      className="w-full md:h-[400px] md:object-contain"
                      alt={product.name}
                      onMouseEnter={() => handleProductEnter(product.id)}
                      onMouseLeave={handleProductLeave}
                    />
                  </div>
                </div>
                <h3 className="text-xs font-semibold flex justify-center mt-4 text-black text-center">
                  {product.name}
                </h3>
                <p className="mt-1 text-xs flex justify-center text-black text-center">
                  ฿{formatPrice(product.price)}
                </p>
                <div className="flex gap-4 justify-center mb-2 opacity-0 group-hover:opacity-100 transform transition-opacity duration-300">
                  {product.category_id !== 33
                    ? ["S", "M", "L", "XL"].map((size) => (
                        <div key={size} className="text-xs font-semibold">
                          {size}
                        </div>
                      ))
                    : ["M", "L", "XL"].map((size) => (
                        <div key={size} className="text-xs font-semibold">
                          {size}
                        </div>
                      ))}
                </div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ProductComponent;
