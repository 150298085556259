import React from "react";

function complete() {
  return (
    <div className="flex flex-col justify-center h-full w-full items-center px-[2%] md:px-[20%]">
      <a
        href="#"
        className="block w-[878px] h-[500px] w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
      >
        <div className="justify-center flex">
          <img className="w-[189px]" src="/icontrue.png" alt="" />
        </div>
        <div>
          <h1
            className="text-3xl flex justify-center mt-4 text-[#737373]"
          >
            จ่ายบิลสำเร็จ
          </h1>
        </div>
        <hr className="mt-10" />
        <div className="flex mt-8 px-7 justify-between">
          <h3
            className="text-xl font-bold"
          >
            จำนวนเงิน
          </h3>
          <h3
            className="text-3xl font-bold"
          >
            590.00
          </h3>
        </div>
        <hr className="mt-6" />
        <div>
          <h1
            className="text-xl flex ml-7 mt-4 text-[#737373]"
          >
            14 / 02/ 2024 เวลา 19 : 00 น.
          </h1>
        </div>
      </a>
      <a href="/">
        <button className="w-full bg-slate-300 hover:bg-black hover:text-white py-2 px-12 my-12 rounded-full">Back to Homepage</button>
      </a>
    </div>
  );
}

export default complete;
